<template>
  <div>
    <div class="header-container">
      <el-header>
        <div class="header-left">
          <img
            src="../../../assets/msxt0231010113609.png"
            style="height: 35px"
          />
        </div>
        <div class="header-right">
          <!-- <el-button round plain @click="exitLogin">退出登录</el-button> -->
          <!-- <el-dropdown>
            <span class="el-dropdown-link">
              {{ form.phone }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item></el-dropdown-item>
              <el-dropdown-item>
                <div>{{ form.nc }}</div>
                <div>{{ card }}</div>
              </el-dropdown-item>
              <div
                style="
                  height: 1px;
                  width: 80%;
                  background-color: #dadada;
                  margin-right: 10%;
                  margin-left: 10%;
                "
              ></div>
              <el-dropdown-item>
                <span @click="exitLogin">退出登录</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->
          <!-- <el-button @click="mffbfj"  round plain class="el-button"
            >发布房间</el-button
          > -->
        </div>
      </el-header>
    </div>
    <div>
      <div class="tishi">
        <div style="margin-right: 20px">
          <img
            style="width: 38px; height: 40px"
            src="https://www.xiaozhu.com/landlordStatic/img/light-bulb.e4784f66.png"
            alt=""
          />
        </div>
        <div class="tishiwenzi">
          <p
            style="
              font-family: PingFangSC-Semibold;
              font-size: 16px;
              line-height: 0px;
              font-weight: 600;
            "
          >
            选择发布民宿房源或者酒店类型会有哪些明显区别？
          </p>
          <p
            style="
              font-family: PingFangSC-Regular;
              font-size: 14px;
              color: #212121;
              line-height: 0px;
              font-weight: 400;
            "
          >
            1、民宿房源代表了分散式经营，同一个地点有一两套房源，一般为小区住宅、集中式公寓
            <!-- <span
              style="
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: #3c445f;
                cursor: pointer;
                border-bottom: 1px solid #3c445f;
              "
              @mouseover="sl1ff"
              @mouseleave="sl1ffleave"
              >民宿展示样式示例</span
            > -->
          </p>
          <p
            style="
              font-family: PingFangSC-Regular;
              font-size: 14px;
              color: #212121;
              line-height: 0px;
              font-weight: 400;
            "
          >
            2、酒店代表了集中式经营，同一个地点有很多套房源，常见的有客栈、酒店等
            <!-- <span
              style="
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: #3c445f;
                cursor: pointer;
                border-bottom: 1px solid #3c445f;
              "
              @mouseover="sl2ff"
              @mouseleave="sl2ffleave"
              >民宿展示样式示例</span
            > -->
          </p>
        </div>
      </div>
      <div class="bodycss">
        <p
          style="
            font-family: PingFangSC-Semibold;
            font-size: 16px;
            color: #212121;
            font-weight: 600;
          "
        >
          请选择发布类型
        </p>
        <!-- 选择类型 -->
        <div
          style="
            margin-top: 20px;
            box-sizing: border-box;
            width: 1200px;
            height: 120px;
            display: flex;
          "
        >
          <div>
            <el-radio @input="fbmszy" v-model="radio1" label="1" border
              >发布民宿资源</el-radio
            >
            <!-- <el-radio @input="fbjd" v-model="radio1" label="2" border>发布酒店</el-radio> -->
          </div>
        </div>
        <!-- 材料 -->
        <div>
          <div
            style="
              font-family: PingFangSC-Regular;
              font-size: 20px;
              color: #757575;
              letter-spacing: 0;
              font-weight: 400;
              margin-bottom: 30px;
            "
          >
            请提前准备好以下材料
          </div>
          <div v-if="this.radio1 == 1">
            <div
              style="
                font-family: PingFangSC-Semibold;
                font-size: 16px;
                color: #212121;
                font-weight: 600;
                margin-bottom: 20px;
              "
            >
              民宿房源图片
            </div>
            <ul style="display: flex; margin-bottom: 20px">
              <div class="imgli">
                <img
                  style="width: 100%; height: 140px; margin-bottom: 10px"
                  src="../../../assets/ms1.png"
                  alt=""
                />
              </div>
              <div class="imgli">
                <img
                  style="width: 100%; height: 140px; margin-bottom: 10px"
                  src="../../../assets/ms2.png"
                  alt=""
                />
              </div>
              <div class="imgli">
                <img
                  style="width: 100%; height: 140px; margin-bottom: 10px"
                  src="../../../assets/ms3.png"
                  alt=""
                />
              </div>
            </ul>
          </div>
          <div v-if="this.radio1 == 2">
            <div
              style="
                font-family: PingFangSC-Semibold;
                font-size: 16px;
                color: #212121;
                font-weight: 600;
                margin-bottom: 20px;
              "
            >
              酒店图片
            </div>
            <ul style="display: flex; margin-bottom: 20px">
              <div class="imgli">
                <img
                  style="width: 100%; height: 140px; margin-bottom: 10px"
                  src="../../../assets/jd1.png"
                  alt=""
                />
              </div>
              <div class="imgli">
                <img
                  style="width: 100%; height: 140px; margin-bottom: 10px"
                  src="../../../assets/jd2.png"
                  alt=""
                />
              </div>
              <div class="imgli">
                <img
                  style="width: 100%; height: 140px; margin-bottom: 10px"
                  src="../../../assets/jd3.png"
                  alt=""
                />
              </div>
            </ul>
            <div
              style="
                font-family: PingFangSC-Semibold;
                font-size: 16px;
                color: #212121;
                font-weight: 600;
                margin-bottom: 20px;
              "
            >
              资质信息
            </div>
            <ul style="display: flex; margin-bottom: 20px">
              <div class="imgli">
                <img
                  style="width: 100%; height: 140px; margin-bottom: 10px"
                  src="../../../assets/zz1.png"
                  alt=""
                />
              </div>
              <div class="imgli">
                <img
                  style="width: 100%; height: 140px; margin-bottom: 10px"
                  src="../../../assets/zz2.png"
                  alt=""
                />
              </div>
              <div class="imgli">
                <img
                  style="width: 100%; height: 140px; margin-bottom: 10px"
                  src="../../../assets/zz3.png"
                  alt=""
                />
              </div>
              <div class="imgli">
                <img
                  style="width: 100%; height: 140px; margin-bottom: 10px"
                  src="../../../assets/zz4.png"
                  alt=""
                />
              </div>
            </ul>
          </div>
        </div>
      </div>
      <img
        v-if="sl1show"
        style="position: absolute; top: 1%; left: 20%; width: 180px"
        src="../../../assets/sl1.png"
        alt=""
      />
      <img
        v-if="sl2show"
        style="position: absolute; top: 1%; left: 20%; width: 180px"
        src="../../../assets/sl2.png"
        alt=""
      />
      <!-- 底部 -->
      <div style="width: 100%; position: fixed; bottom: 0">
        <div class="bottom-wrapper">
          <div
            style="
              font-size: 16px;
              margin-left: 30px;
              line-height: 22px;
              color: #212121;
              cursor: pointer;
            "
          >
            <el-button @click="outtc" round>退出</el-button>
          </div>
          <div style="display: flex">
            <el-button style="color: #fff" @click="nextbz" type="danger" round
              >下一步</el-button
            >
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="提醒"
      :visible.sync="dialogVisibleRz"
      width="30%"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div>
        <div>请完善您的个人资料信息，然后再添加房源吧</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toInfoRz">去完善</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: "1",
      radio1: "1",
      sl1show: false,
      sl2show: false,
      dialogVisibleRz: false,
      card: "",
      form: {},
    };
  },
  created() {
    this.$axios
      .get("/api/adminPC/msOperator/getLoginInfo")
      .then((res) => {
        if (res.data.data.sfrz == "0") {
          // 未完成认证,需要验证才能添加房源
          this.dialogVisibleRz = true;
        }
      })
      .catch((err) => {
        console.log(err);
      });

    let cardNum = "130132199910210012";
    this.card = cardNum.replace(/(\w{6})\w*(\w{4})/, "$1******$2");
    this.getLoginInfo();
  },
  methods: {
        getLoginInfo() {
      this.$axios
        .get("/api/adminPC/msOperator/getLoginInfo")
        .then((res) => {
          this.form = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    mffbfj() {
      this.$router.push("/fyfb");
    },
    exitLogin() {
      this.$axios
        .get("/api/adminPC/msWeiXinController/logout")
        .then((res) => {
          this.$message.success("退出成功");
          this.$router.push("/");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    outtc() {
      this.$router.push("/kzt");
    },
    nextbz() {
      this.$router.push("./fyfbnext");
    },
    toInfoRz() {
      this.$router.push("/grzl");
    },
    sl1ff() {
      this.sl1show = true;
    },
    sl2ff() {
      this.sl2show = true;
    },
    sl1ffleave() {
      this.sl1show = false;
    },
    sl2ffleave() {
      this.sl2show = false;
    },
    fbmszy() {
      console.log(this.radio1, "1");
    },
    fbjd() {
      console.log(this.radio1, "21");
    },
  },
};
</script>

<style scoped>
.bottom-wrapper {
  width: 96%;
  height: 70px;
  background: #fff;
  margin: 0 auto;
  border-radius: 8px;
  align-items: center;
  position: relative;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.05);
}

.imgli {
  width: 210px;
  margin-right: 20px;
  text-align: center;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #212121;
  font-weight: 400;
}

.bodycss {
  width: 96%;
  min-height: 100vh;
  background: #fff;
  border-radius: 8px;
  margin: 0 auto;
  padding-left: 30px;
  padding-top: 30px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.tishiwenzi {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.tishi {
  width: 96%;
  height: 118px;
  background: #fff9f9;
  border-radius: 8px;
  padding: 20px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.header-container {
  height: 60px;
  /* position: fixed; */
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: #fff;
}

.header-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.el-header {
  padding: 0 30px;
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.el-dropdown-link {
  font-size: 14px;
  color: #212121;
  width: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 84px;
  letter-spacing: 2px;
  padding-right: 6px;
}

/* .el-button {
  position: relative;
  width: 143px;
  box-sizing: border-box;
  height: 40px;
  line-height: 38px;
  padding: 0 17px;
  border: 2px solid #ff5f7a;
  border-radius: 25px;
  letter-spacing: 2px;
  color: #ff5f7a;
  text-align: center;
  margin: 20px;
} */
</style>