import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import Avue from '@smallwei/avue';
import '@smallwei/avue/lib/index.css';
Vue.use(Avue);
import ElementUI from 'element-ui';
Vue.use(ElementUI);
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(VueRouter);
import router from './router'
// 引入Echarts
import echarts from "echarts"
Vue.prototype.$echarts = echarts
// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view'
Vue.use(dataV)

//main.js
import axios from 'axios'
//把axios对象挂到Vue实例上面，使用axios的时候直接全局调用this.$axios就可以了 
Vue.prototype.$axios = axios

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
