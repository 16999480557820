<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
#app {
  padding: 0;
  margin: 0;
  color: #000;
}

body{
  margin: 0;
  color: #000;
}

/**修改全局的滚动条*/
/**滚动条的宽度*/
::-webkit-scrollbar {
  width: 8px; 
 
}

::-webkit-scrollbar-thumb {
  background-color: #d6d8db;
  border-radius: 3px;
}

a {
  font-size: 16px
}

a:link {
  color: blue;
  text-decoration: none;
}

a:active {
  color: red;
}

a:visited {
  color: purple;
  text-decoration: none;
}

a:hover {
  color: red;
  text-decoration: underline;
}


</style>
