<template>
  <!-- 二级、三级菜单封装 -->
  <!-- <div class="sidebar"> -->
  <el-menu class="sidebar-el-menu" :default-active="toIndex()" router>
    <div>
      <el-menu-item index="kzt">
        <i class="el-icon-house"></i>
        <span slot="title">首页</span>
      </el-menu-item>

      <el-menu-item index="ftrl">
        <i class="el-icon-date"></i>
        <span slot="title">房态日历</span>
      </el-menu-item>
      <el-menu-item index="zkxx">
        <i class="el-icon-user"></i>
        <span slot="title">住客信息</span>
      </el-menu-item>
      <el-menu-item index="msdd">
        <i class="el-icon-document-copy"></i>
        <span slot="title">订单管理</span>
      </el-menu-item>
      <!-- <el-menu-item index="msgl">
        <i class="el-icon-refrigerator"></i>
        <span slot="title">门锁管理</span>
      </el-menu-item> -->
      <el-menu-item index="fylb"
        ><i class="el-icon-discount"></i>
        <span slot="title">房间管理</span>
      </el-menu-item>
      <el-menu-item v-if="this.shtyxydm != 'undefined'" index="yggl">
        <i class="el-icon-open"></i>
        <span slot="title">员工管理</span>
      </el-menu-item>

      <el-menu-item index="fblb"
        ><i class="el-icon-notebook-2"></i>
        <span slot="title">发布列表</span>
      </el-menu-item>

      <div v-if="this.shtyxydm != 'undefined'">
        <el-submenu
          :index="index + ''"
          v-for="(list, index) in lists"
          :key="list.id"
        >
          <template slot="title">
            <i class="el-icon-setting"></i>
            <span>{{ list.title }}</span>
          </template>
          <template v-for="item in list.children">
            <el-menu-item :index="item.index" :key="item.id" v-if="item">
              <!-- <i class="el-icon-s-operation"></i> -->
              <span slot="title">{{ item.title }}</span>
            </el-menu-item>
          </template>
        </el-submenu>
      </div>
            <el-menu-item index="czpz" disabled
        ><i class="el-icon-set-up"></i>
        <span slot="title">充值配置</span>
      </el-menu-item>
      <el-menu-item index="hytx" disabled
        ><i class="el-icon-coin"></i>
        <span slot="title">会员体系</span>
      </el-menu-item>
            <el-submenu index="jdgd" disabled>
        <template slot="title">
          <i class="el-icon-document"></i>
          <span>民宿工单</span>
        </template>
        <template>
          <el-menu-item index="bjds">
            <span slot="title">保洁打扫</span>
          </el-menu-item>
          <el-menu-item index="gcwx">
            <span slot="title">工程维修</span>
          </el-menu-item>
        </template>
      </el-submenu>
      <el-submenu index="scgl" disabled>
        <template slot="title">
          <i class="el-icon-goods"></i>
          <span>商城管理</span>
        </template>
        <template>
          <el-menu-item index="splb">
            <span slot="title">商品列表</span>
          </el-menu-item>
          <el-menu-item index="scdd">
            <span slot="title">商城订单</span>
          </el-menu-item>
        </template>
      </el-submenu>
    </div>
  </el-menu>
  <!-- <el-menu default-active="2" class="el-menu-vertical-demo">
      <el-submenu index="ftgl">
        <template slot="title">
          <span>房源管理</span>
        </template>
        <el-menu-item index="fyfb">房源发布</el-menu-item>
        <el-menu-item index="fylb">房源列表</el-menu-item>
        <el-menu-item index="ftrl">房态日历</el-menu-item>
      </el-submenu>
      <el-menu-item index="msdd">
        <span slot="title">订单管理</span>
      </el-menu-item>
      <el-menu-item index="dpgl">
        <span slot="title">点评管理</span>
      </el-menu-item>
      <el-submenu index="sz">
        <template slot="title">
          <span>设置</span>
        </template>
        <el-menu-item index="fyfb">房源发布</el-menu-item>
        <el-menu-item index="fylb">房源列表</el-menu-item>
        <el-menu-item index="ftrl">房态日历</el-menu-item>
      </el-submenu>
    </el-menu> -->
  <!-- </div> -->
</template>

<script>
export default {
  props: ["lists"],
  data() {
    return {
      shtyxydm: "",
      jdgdlist: [],
    };
  },
  methods: {
    // 根据路径绑定到对应的二级菜单，防止页面刷新重新跳回第一个
    toIndex() {
      return this.$route.path.split("/")[2];
    },
    changepath(path) {},
  },
  created() {
    this.shtyxydm = localStorage.getItem("shtyxydm");
  },
  mounted() {},
};
</script>


<style scoped>
/* .sidebar {
  height: 100%;
} */
.sidebar-el-menu {
  height: 100%;
}
/* .el-submenu__title {
  font-weight: 600;
  color: black !important;
} */

.el-menu-item {
  font-weight: 400;
  /* color: black !important; */
}
.el-menu-item.is-active {
  background-color: #e8f3ff !important;
  /* color: #fff; */
  font-weight: 400;
  font-size: 14px;
}
</style>