<template>
  <div style="transform: translateX(10%)">
    <div class="main-view">
      <div style="position: relative; margin-bottom: 30px">
        <div style="position: relative; margin-bottom: 30px">
          <div class="left-icon"></div>
          <h5
            style="
              height: 27px;
              line-height: 22px;
              font-size: 16px;
              color: #212121;
              font-weight: 600;
            "
          >
            单位信息
          </h5>
        </div>
        <!-- <div class="info-header">
          <div class="image-wrapper">
            <img src="https://image.xiaozhustatic1.com/00,360,360/s,3,18iRc,254,254,2,30fa9e23.jpg"
              style="width: 90px; height: 90px;" />
          </div>
          <div>
            <el-button round
              style="width: 110px; height: 40px; font-weight: 600; margin-left: 30px; padding: 8px 0; text-align: center;border-color: #000; color: #000;">修改头像</el-button>
          </div>
        </div> -->
        <div>
          <el-form
            ref="form"
            :model="form"
            :rules="formRules"
            label-width="130px"
          >
            <el-form-item label="民宿名称" prop="msmc">
              <el-input
                v-model="form.msmc"
                placeholder="请输入民宿名称"
                style="width: 20%; margin-right: 30px"
              ></el-input>
            </el-form-item>
            <el-form-item label="单位名称" prop="dwmc">
              <el-input
                v-model="form.dwmc"
                placeholder="请输入单位名称"
                style="width: 20%; margin-right: 30px"
              ></el-input>
            </el-form-item>
            <el-form-item label="单位分类" prop="dwfl">
              <el-select
                style="width: 20%; margin-right: 30px"
                v-model="form.dwfl"
                placeholder="请选择单位分类"
              >
                <el-option
                  v-for="(item, index) in optionsdwfl"
                  :key="index"
                  :label="item.label"
                  :value="item.label"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="网约房平台名称" prop="wyptmc">
              <el-select
                style="width: 20%; margin-right: 30px"
                v-model="form.wyptmc"
                placeholder="请选择网约房平台名称"
              >
                <el-option
                  v-for="(item, index) in optionswyfmc"
                  :key="index"
                  :label="item.label"
                  :value="item.label"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="网约房平台电话" prop="wyptdh">
              <el-input
                v-model="form.wyptdh"
                placeholder="请输入网约房平台电话"
                style="width: 20%; margin-right: 30px"
              ></el-input>
            </el-form-item>
            <el-form-item label="社会统一信用代码" prop="shtyxydm">
              <el-input
                v-model="form.shtyxydm"
                placeholder="请输入社会统一信用代码"
                style="width: 20%; margin-right: 30px"
              ></el-input>
            </el-form-item>
            <el-form-item label="营业执照照片" prop="yyzzzp">
              <el-upload
                class="avatar-uploader"
                action="http://st11.sjz110.cc:/api/platform/pub/file/upload/imageFw"
                :show-file-list="false"
                :on-success="handleUploadSuccessYyzz"
                :before-upload="beforeUploadYyzz"
              >
                <img
                  v-if="form.yyzzzp && imageUrl1 == ''"
                  :src="'http://st11.sjz110.cc/images' + form.yyzzzp"
                  class="avatar"
                />
                <img
                  v-else-if="imageUrl1 != ''"
                  :src="imageUrl1"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="单位地址" prop="dwdz">
              <el-input
                v-model="form.dwdz"
                placeholder="请输入单位地址"
                style="width: 20%; margin-right: 30px"
              ></el-input>
            </el-form-item>
            <el-form-item label="管辖单位" prop="gxdwmc">
              <el-cascader
                ref="cascaderItem"
                @change="handleChange"
                :props="optionProps"
                style="width: 20%; margin-right: 30px"
                placeholder="请选择管辖单位"
                :options="optionsgxdw"
                clearable
              ></el-cascader>
            </el-form-item>

            <div class="left-icon"></div>
            <h5
              style="
                height: 27px;
                line-height: 22px;
                font-size: 16px;
                color: #212121;
                font-weight: 600;
              "
            >
              法人信息
            </h5>
            <el-form-item label="法人姓名" prop="frxm">
              <el-input
                v-model="form.frxm"
                placeholder="请输入法人姓名"
                style="width: 20%; margin-right: 30px"
              ></el-input>
            </el-form-item>
            <el-form-item label="法人身份证" prop="frsfz">
              <el-input
                v-model="form.frsfz"
                placeholder="请输入法人身份证"
                style="width: 20%; margin-right: 30px"
              ></el-input>
            </el-form-item>
            <el-form-item label="法人电话" prop="frdh">
              <el-input
                v-model="form.frdh"
                placeholder="请输入法人电话"
                style="width: 20%; margin-right: 30px"
              ></el-input>
            </el-form-item>

            <el-form-item label="法人身份证正面照片" prop="frsfzzp">
              <el-upload
                class="avatar-uploader"
                action="http://st11.sjz110.cc:/api/platform/pub/file/upload/imageFw"
                :show-file-list="false"
                :on-success="handleUploadSuccessFrsfz"
                :before-upload="beforeUploadFrsfz"
              >
                <img
                  v-if="form.frsfzzp && imageUrl2 == ''"
                  :src="'http://st11.sjz110.cc/images' + form.frsfzzp"
                  class="avatar"
                />
                <img
                  v-else-if="imageUrl2 != ''"
                  :src="imageUrl2"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>

            <el-form-item label="法人身份证反面照片" prop="frsfzzpfm">
              <el-upload
                class="avatar-uploader"
                action="http://st11.sjz110.cc:/api/platform/pub/file/upload/imageFw"
                :show-file-list="false"
                :on-success="handleUploadSuccessFrsfzfm"
                :before-upload="beforeUploadFrsfzfm"
              >
                <img
                  v-if="form.frsfzzpfm && imageUrl2fm == ''"
                  :src="'http://st11.sjz110.cc/images' + form.frsfzzpfm"
                  class="avatar"
                />
                <img
                  v-else-if="imageUrl2fm != ''"
                  :src="imageUrl2fm"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>

            <div class="left-icon"></div>
            <h5
              style="
                height: 27px;
                line-height: 22px;
                font-size: 16px;
                color: #212121;
                font-weight: 600;
              "
            >
              负责人信息
            </h5>
            <el-form-item label="负责人姓名" prop="fzrxm">
              <el-input
                v-model="form.fzrxm"
                placeholder="请输入负责人姓名"
                style="width: 20%; margin-right: 30px"
              ></el-input>
            </el-form-item>
            <el-form-item label="负责人民族" prop="fzrmz">
              <el-select
                style="width: 20%; margin-right: 30px"
                v-model="form.fzrmz"
                placeholder="请选择负责人民族"
              >
                <el-option
                  v-for="(item, index) in optionsfzrmz"
                  :key="index"
                  :label="item.label"
                  :value="item.label"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="负责人居住地" prop="fzrjzd">
              <el-input
                v-model="form.fzrjzd"
                placeholder="请输入负责人居住地"
                style="width: 20%; margin-right: 30px"
              ></el-input>
            </el-form-item>
            <el-form-item label="负责人身份证" prop="fzrsfz">
              <el-input
                v-model="form.fzrsfz"
                placeholder="请输入负责人身份证"
                style="width: 20%; margin-right: 30px"
              ></el-input>
            </el-form-item>
            <el-form-item label="负责人身份证正面照片" prop="fzrsfzzp">
              <el-upload
                class="avatar-uploader"
                action="http://st11.sjz110.cc:/api/platform/pub/file/upload/imageFw"
                :show-file-list="false"
                :on-success="handleUploadSuccessFzrsfzzp"
                :before-upload="beforeUploadFzrsfzzp"
              >
                <img
                  v-if="form.fzrsfzzp && imageUrl3 == ''"
                  :src="'http://st11.sjz110.cc/images' + form.fzrsfzzp"
                  class="avatar"
                />
                <img
                  v-else-if="imageUrl3 != ''"
                  :src="imageUrl3"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="负责人身份证反面照片" prop="fzrsfzzp">
              <el-upload
                class="avatar-uploader"
                action="http://st11.sjz110.cc:/api/platform/pub/file/upload/imageFw"
                :show-file-list="false"
                :on-success="handleUploadSuccessFzrsfzzpfm"
                :before-upload="beforeUploadFzrsfzzpfm"
              >
                <img
                  v-if="form.fzrsfzzpfm && imageUrl3fm == ''"
                  :src="'http://st11.sjz110.cc/images' + form.fzrsfzzpfm"
                  class="avatar"
                />
                <img
                  v-else-if="imageUrl3fm != ''"
                  :src="imageUrl3fm"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="登录手机号" prop="dlzh">
              <el-input
                v-model="form.dlzh"
                placeholder="请输入登录手机号"
                style="width: 20%; margin-right: 30px"
              ></el-input>
            </el-form-item>
            <el-form-item label="登录密码" prop="dlmm">
              <el-input
                v-model="form.dlmm"
                placeholder="请输入登录密码"
                style="width: 20%; margin-right: 30px"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                round
                style="width: 30%"
                @click="updateAll"
                >注册</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as imageConversion from "image-conversion";

export default {
  data() {
    return {
      gxdwmclist: [],
      optionProps: {
        value: "value",
        label: "label",
        children: "children",
        // checkStrictly: true, //选择任意一级
      },
      optionsgxdw: [],
      optionsfzrmz: [],
      form: {},
      imageUrl1: "",
      imageUrl2: "",
      imageUrl2fm: "",
      imageUrl3: "",
      imageUrl3fm: "",
      optionsdwfl: [],
      optionswyfmc: [
        {
          value: "美团",
          label: "美团",
        },
        {
          value: "飞猪",
          label: "飞猪",
        },
        {
          value: "携程",
          label: "携程",
        },
      ],
      formRules: {
        msmc: [{ required: true, message: "请输入民宿名称", trigger: "blur" }],
        dwmc: [{ required: true, message: "请输入单位名称", trigger: "blur" }],
        dwfl: [{ required: true, message: "请输入单位分类", trigger: "blur" }],
        wyptmc: [
          { required: true, message: "请输入网约平台名称", trigger: "blur" },
        ],
        wyptdh: [
          { required: true, message: "请输入网约平台电话", trigger: "blur" },
        ],
        shtyxydm: [
          {
            required: true,
            message: "请输入社会统一信用代码",
            trigger: "blur",
          },
        ],
        yyzzzp: [
          { required: true, message: "请上传营业执照照片", trigger: "blur" },
        ],
        dwdz: [{ required: true, message: "请输入单位地址", trigger: "blur" }],
        gxdwmc: [
          { required: true, message: "请选择管辖单位", trigger: "blur" },
        ],
        fzrmz: [
          { required: true, message: "请选择负责人民族", trigger: "blur" },
        ],
        frxm: [{ required: true, message: "请输入法人姓名", trigger: "blur" }],
        frsfz: [
          { required: true, message: "请输入法人身份证", trigger: "blur" },
          {
            pattern:
              /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,
            message: "证件号码格式有误！",
            trigger: "blur",
          },
        ],
        frdh: [
          { required: true, message: "请输入法人电话", trigger: "blur" },
          {
            required: true,
            pattern:
              /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        frsfzzp: [
          {
            required: true,
            message: "请上传法人身份证正面照片",
            trigger: "blur",
          },
        ],
        frsfzzpfm: [
          {
            required: true,
            message: "请上传法人身份证反面照片",
            trigger: "blur",
          },
        ],

        fzrxm: [
          { required: true, message: "请输入负责人姓名", trigger: "blur" },
        ],
        fzrjzd: [
          { required: true, message: "请输入负责人居住地", trigger: "blur" },
        ],
        fzrsfz: [
          { required: true, message: "请输入负责人身份证", trigger: "blur" },
          {
            pattern:
              /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,
            message: "证件号码格式有误！",
            trigger: "blur",
          },
        ],
        dlzh: [
          { required: true, message: "请输入登录手机号", trigger: "blur" },
          {
            required: true,
            pattern:
              /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        dlmm: [{ required: true, message: "请输入登录密码", trigger: "blur" }],
        fzrsfzzp: [
          {
            required: true,
            message: "请上传负责人身份证正面照片",
            trigger: "blur",
          },
        ],
        fzrsfzzpfm: [
          {
            required: true,
            message: "请上传负责人身份证反面照片",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.SysUnitZddw();
    this.getmzlist();
    this.getdwfl();
  },
  methods: {
    handleChange(val) {
      this.form.gxdwbm = val[2];
    },
    getdwfl() {
      this.$axios
        .get("/api/adminPC/PMS/getdwfl")
        .then((res) => {
          this.optionsdwfl = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    SysUnitZddw() {
      this.$axios
        .get("/api/adminPC/SysUnitZddw/unit")
        .then((res) => {
          this.optionsgxdw = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getmzlist() {
      this.$axios
        .post("/api/adminPC/MsOperator/mzlist")
        .then((res) => {
          this.optionsfzrmz = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    beforeUploadYyzz(file) {
      return new Promise((resolve, reject) => {
        let isLt2M = file.size / 1024 / 1024 < 1; // 判定图片大小是否小于4MB
        if (isLt2M) {
          resolve(file);
        }
        console.log(file); // 压缩到400KB,这里的400就是要压缩的大小,可自定义
        imageConversion.compressAccurately(file, 400).then((res) => {
          console.log(res)
          resolve(res);
        });
      });
    },
    beforeUploadFrsfz(file) {
      return new Promise((resolve, reject) => {
        let isLt2M = file.size / 1024 / 1024 < 1; // 判定图片大小是否小于4MB
        if (isLt2M) {
          resolve(file);
        }
        console.log(file); // 压缩到400KB,这里的400就是要压缩的大小,可自定义
        imageConversion.compressAccurately(file, 400).then((res) => {
          // console.log(res)
          resolve(res);
        });
      });
    },
    beforeUploadFrsfzfm(file) {
      return new Promise((resolve, reject) => {
        let isLt2M = file.size / 1024 / 1024 < 1; // 判定图片大小是否小于4MB
        if (isLt2M) {
          resolve(file);
        }
        console.log(file); // 压缩到400KB,这里的400就是要压缩的大小,可自定义
        imageConversion.compressAccurately(file, 400).then((res) => {
          // console.log(res)
          resolve(res);
        });
      });
    },
    beforeUploadFzrsfzzp(file) {
      return new Promise((resolve, reject) => {
        let isLt2M = file.size / 1024 / 1024 < 1; // 判定图片大小是否小于4MB
        if (isLt2M) {
          resolve(file);
        }
        console.log(file); // 压缩到400KB,这里的400就是要压缩的大小,可自定义
        imageConversion.compressAccurately(file, 400).then((res) => {
          // console.log(res)
          resolve(res);
        });
      });
    },
    beforeUploadFzrsfzzpfm(file) {
      return new Promise((resolve, reject) => {
        let isLt2M = file.size / 1024 / 1024 < 1; // 判定图片大小是否小于4MB
        if (isLt2M) {
          resolve(file);
        }
        console.log(file); // 压缩到400KB,这里的400就是要压缩的大小,可自定义
        imageConversion.compressAccurately(file, 400).then((res) => {
          // console.log(res)
          resolve(res);
        });
      });
    },
    // 文件上传成功后进行保存
    handleUploadSuccessYyzz(resp, file) {
      console.log(resp, file);
      if (resp.code !== 0) {
        this.$message.error(resp.msg);
        return;
      }
      this.form.yyzzzp = resp.data.url;
      this.imageUrl1 = URL.createObjectURL(file.raw);
    },
    handleUploadSuccessFrsfz(resp, file) {
      if (resp.code !== 0) {
        this.$message.error(resp.msg);
        return;
      }
      this.form.frsfzzp = resp.data.url;
      this.imageUrl2 = URL.createObjectURL(file.raw);
    },
    handleUploadSuccessFrsfzfm(resp, file) {
      if (resp.code !== 0) {
        this.$message.error(resp.msg);
        return;
      }
      this.form.frsfzzpfm = resp.data.url;
      this.imageUrl2fm = URL.createObjectURL(file.raw);
    },

    handleUploadSuccessFzrsfzzp(resp, file) {
      if (resp.code !== 0) {
        this.$message.error(resp.msg);
        return;
      }
      this.form.fzrsfzzp = resp.data.url;
      this.imageUrl3 = URL.createObjectURL(file.raw);
    },
    handleUploadSuccessFzrsfzzpfm(resp, file) {
      if (resp.code !== 0) {
        this.$message.error(resp.msg);
        return;
      }
      this.form.fzrsfzzpfm = resp.data.url;
      this.imageUrl3fm = URL.createObjectURL(file.raw);
    },

    updateAll() {
      this.form.gxdwmc = this.$refs["cascaderItem"].inputValue;
      console.log(this.form.fzrmz, "mz");
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$axios
            .post("/api/adminPC/PMS/jyzzc", this.form)
            .then((res) => {
              this.$message.success("注册成功");
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.main-view {
  flex: 1;
  position: relative;
  max-width: 1820px;
  padding-right: 30px;
  min-width: 1000px;
  min-height: calc(100vh - 130px);
  overflow: hidden;
  padding: 30px;
}

.left-icon {
  position: absolute;
  width: 5px;
  left: -20px;
  height: 22px;
  background-color: #ff5e79;
}

.info-header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.image-wrapper {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  overflow: hidden;
}

::v-deep .el-upload {
  border: 1px solid #ccc !important;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>