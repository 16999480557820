<template>
  <div>
    <el-container height="80px;">
      <el-header>
        <div class="header-left">
          <div
            style="
              position: absolute;
              left: 1%;
              font-size: 18px;
              font-weight: 700;
              color: #000;
            "
          >
            石家庄民宿(网约房)PMS系统
          </div>
        </div>

        <div class="header-right">
          <el-dropdown>
            <!-- <div style="margin-right: 15px; cursor: pointer">
              <i class="el-icon-headset"></i>
            </div> -->
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <span @click="lxkf">联系客服</span>
              </el-dropdown-item>
              <div
                style="
                  height: 1px;
                  width: 80%;
                  background-color: #dadada;
                  margin-right: 10%;
                  margin-left: 10%;
                "
              ></div>
              <el-dropdown-item>
                <span>常见问题</span>
              </el-dropdown-item>
              <div
                style="
                  height: 1px;
                  width: 80%;
                  background-color: #dadada;
                  margin-right: 10%;
                  margin-left: 10%;
                "
              ></div>
              <el-dropdown-item>
                <span @click="tsjy">建议箱</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <!-- <el-button round plain @click="exitLogin">退出登录</el-button> -->
          <el-button @click="mffbfj"  round plain style="margin-right: 50px;border: 1px solid #409eff;color:#409eff"
            >发布房间</el-button
          >
          <el-dropdown style="line-height: 40px;">
            <span class="el-dropdown-link">
              {{ fzrdh }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item></el-dropdown-item>
              <el-dropdown-item>
                <div>{{ msmc }}</div>
                <!-- <div>{{ form.fzrsfz }}</div> -->
              </el-dropdown-item>
              <div
                style="
                  height: 1px;
                  width: 80%;
                  background-color: #dadada;
                  margin-right: 10%;
                  margin-left: 10%;
                "
              ></div>
              <el-dropdown-item>
                <span @click="exitLogin">退出登录</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

        </div>
      </el-header>
    </el-container>
    <el-dialog
      center
      title="建议箱"
      :visible.sync="dialogVisibletsjy"
      width="30%"
      :before-close="handleClosetsjy"
    >
      <el-form :model="form" label-width="120px">
        <el-form-item label="建议内容" prop="jynr">
          <el-input
            v-model="form.jynr"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            maxlength="50"
            show-word-limit
            placeholder="请输入内容"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="建议图片" prop="jytp">
          <el-upload
            class="avatar-uploader"
            action="http://st11.sjz110.cc:/api/platform/pub/file/upload/imageFw"
            :show-file-list="false"
            :on-success="handleUploadSuccessjytp"
          >
            <img
              v-if="form.jytp && imageUrl1 == ''"
              :src="'http://st11.sjz110.cc/images' + form.jytp"
              class="avatar"
            />
            <img v-else-if="imageUrl1 != ''" :src="imageUrl1" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibletsjy = false">取 消</el-button>
        <el-button type="primary" @click="addjyx">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      center
      title="客服"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div style="text-align: center">
        <img style="width: 400px" src="../assets/kfwx.jpg" alt="" />
        <div style="font-size: 24px">客服电话:0311-85675110 / 13180081255</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisibletsjy: false,
      dialogVisible: false,
      activeIndex: "1",
      form: {},
      msmc: "",
      imageUrl1: "",
    };
  },
  created() {
    // let cardNum = "130132199910210012";
    // this.form.fzrsfz = cardNum.replace(/(\w{6})\w*(\w{4})/, "$1******$2");
    this.getLoginInfo();
  },
  methods: {
    addjyx() {
      this.$axios
        .post("/api/adminPC/MsSuggestion/create", this.form)
        .then((res) => {
          if (res.data.code == 0) {
            this.dialogVisibletsjy = false
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 文件上传成功后进行保存
    handleUploadSuccessjytp(resp, file) {
      console.log(resp, file);
      if (resp.code !== 0) {
        this.$message.error(resp.msg);
        return;
      }
      this.form.jytp = resp.data.url;
      this.imageUrl1 = URL.createObjectURL(file.raw);
    },
    tsjy() {
      this.dialogVisibletsjy = true;
    },
    lxkf() {
      this.dialogVisible = true;
    },
    getLoginInfo() {
      this.msmc = localStorage.getItem("msmc");
      this.fzrdh = localStorage.getItem("fzrdh");
    },
    mffbfj() {
      this.$router.push("/fyfb");
    },

    handleClosetsjy() {
      this.dialogVisibletsjy = false;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    exitLogin() {
      this.$axios
        .get("/api/adminPC/PMS/logout")
        .then((res) => {
          this.$message.success("退出成功");
          this.$router.push("/");
          window.localStorage.removeItem("token");
          window.localStorage.removeItem("msmc");
          window.localStorage.removeItem("fzrdh");
          window.localStorage.removeItem("shtyxydm");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.image-wrapper {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  overflow: hidden;
}

::v-deep .el-upload {
  border: 1px solid #ccc !important;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.header-container {
  height: 80px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: #fff;
}

.header-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.header-right {
  display: flex;
  justify-content: flex-end;
}

.el-header {
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff !important;
}

.el-dropdown-link {
  font-size: 14px;
  color: #000;
  width: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 84px;
  letter-spacing: 2px;
  padding-right: 6px;
  cursor: pointer;
}

/* .el-button { */
  /* position: relative;
  width: 143px;
  box-sizing: border-box;
  height: 40px;
  line-height: 38px;
  padding: 0 17px;
  border: 2px solid #ff5f7a;
  border-radius: 25px;
  letter-spacing: 2px;
  color: #ff5f7a;
  text-align: center;
  margin: 20px; */
/* } */
</style>