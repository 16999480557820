<template>
  <div style="padding:30px">
    <!-- <img style="width: 100%;" src="@/assets/cjwt.jpg" alt=""> -->
		<!-- 不需要引入，可直接使用 -->
		<image v-show="this.html == ''" style="width: 100%;height: 65vh;" src="/static/zwsj.png" mode=""></image>
		<!-- <mp-html :content="html" /> -->
		<h2 style="text-align:center;">关于门锁联网工作过程中的问题说明的</h2>
		<h3 style="margin-top: 30px;">一、门锁对接的意义的</h3>
		<p>{{&nbsp;}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;门锁成功对接平台后，实现了与旅馆业治安管理系统的联网。登记入住的人员信息能够自动上传至旅馆业治安管理系统，等同于安装了旅馆业治安系统。
		</p>
		<p>{{&nbsp;}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;门锁对接成功后，每个房间有了专属的二维码门牌，这不仅进一步凸显了房间的合法性和合规性，也为旅客提供了便捷的信息获取途径。为积极响应石家庄城市夜经济、摇滚之都等宣传活动，相关单位为合法合规的经营者提供宣传和推广支持。
		</p>
		<p>{{&nbsp;}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;二维码门牌不仅是房间的合法身份凭证，也是公安机关对经营者进行定期检查的重要依据。
		</p>
		<h3>二、对于经营者的优点</h3>
		<p>{{&nbsp;}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;对于民宿/网约房经营者而言，门锁对接带来了诸多显著优势。首先，它极大提升了经营的便捷性，经营者仅需在手机或电脑上简单录入入住的起止时间，旅客即可通过扫码完成登记并自助开锁，彻底告别了传统的交钥匙、发卡片等繁琐环节。这不仅简化了入住流程，还真正实现了旅客的无人自助入住体验，提升了整体服务效率。
		</p>
		<p>{{&nbsp;}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;更为重要的是，通过这一系统，经营者无需再专门设置前台，从而有效减少了房租和人工成本。这不仅降低了经营者的运营压力，还使其能够将更多资源投入到提升服务质量和旅客体验上。
		</p>
		<h3>三、关于房间如何登记</h3>
		<p>{{&nbsp;}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.对于房屋登记的问题，网约房或民宿的经营者首先需要前往“互联网治安政务服务”微信公众号进行账号注册。随后，务必准确、完整地上传房屋信息等相关资料。资料上传完毕后，请主动联系辖区派出所的民警，以便对您的房屋进行审核，房屋通过民警审核后，您即可开始经营。
		</p>
		<p>{{&nbsp;}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.添加房屋资料时没有找到小区？请联系客服（客服微信13180081255）。
		</p>
		<p>{{&nbsp;}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.楼号单元号门牌号等信息怎么写？
		</p>
		<p>{{&nbsp;}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;楼号：写所属楼号，比如A座、南栋等；
		</p>
		<p>{{&nbsp;}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;单元号：不区分单元号的公寓，单元号写0；
		</p>
		<p>{{&nbsp;}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;门牌号：写房间号，如101  1503等；
		</p>
		<p>{{&nbsp;}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;房间数：每个屋子的房间数，主要针对家庭套房的情况，可出行多房间情况；
		</p>
		<p>{{&nbsp;}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;房产证照片：还未发放房产证的房间，可拍购房合同或租房合同主页；
		</p>
		<p>{{&nbsp;}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;房东姓名、房东手机号 房东身份证号均为房屋产权人信息；
		</p>
		<p>{{&nbsp;}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;每个房间需要独立登记，有几间房，需要按上面流程登记几间，不能把所有房间号一起写到门牌号位置。
		</p>
		<h3>四、关于电子门锁联网</h3>
		<p>{{&nbsp;}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.关于电子门锁的对接要求：首先，电子门锁必须具备机械钥匙开锁功能、密码开锁功能、蓝牙开锁功能。只要门锁满足上述条件，即可与平台进行对接。
		</p>
		<p>{{&nbsp;}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.鉴于市场上电子锁品牌型号众多，平台无法一次性完成与所有品牌和型号的对接工作。目前平台已经对近三年来上市的80%的电子门锁进行了对接（目前已对接品牌有科技侠、智奇点、觅束、迅旅锁、沃协锁、赛脑智能等，安伴等更多品牌正在对接中）。对于尚未与平台完成对接的品牌型号，我们欢迎相关厂家主动联系平台，获取对接文档并开始对接工作。平台将提供必要的支持和协助，以确保对接工作的顺利进行。
		</p>
		<p>{{&nbsp;}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.关于费用问题，平台与所有门锁厂家均明确承诺，对接过程中不会收取任何费用。门锁厂家不得以对接平台为由，向经营者收取或变相收取接口费用。
		</p>
		<p>{{&nbsp;}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.经营者应当充分利旧，在满足安全性能要求的前提下，尽可能利用现有门锁进行升级或改造，以减少不必要的浪费和成本支出。
		</p>
		<p>{{&nbsp;}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.关于门锁是否在可以升级的范围内，经营者需要与厂家或经销商联系，以获取最准确的信息。
		</p>
		<h3>五、关于旅客入住流程</h3>
		<p>{{&nbsp;}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.入住流程：
		</p>
		<p>{{&nbsp;}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第一步经营者在平台生成《入住码》;
		</p>
		<p>{{&nbsp;}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第二步旅客扫描张贴在房间门口二维码，并正确录入《入住码》;
		</p>
		<p>{{&nbsp;}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第三步输入旅客本人身份信息并进行人身核验;
		</p>
		<p>{{&nbsp;}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第四步点击手机中开锁按钮即可打开房门。
		</p>
		<p>{{&nbsp;}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.为什么无法办理入住？新登记房间需派出所通过审核通过后，才能办理入住（民警审核30分钟后，房间方可办理入住）。
		</p>
		<p>{{&nbsp;}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.扫码填写信息人脸人证后提示入住码失效？每个入住码生成的时候选择订单时间，超出或未到达所选择时间时，会出现该情况。
		</p>
		<p>{{&nbsp;}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.为什么点一键开锁没反应？可能存在一下几种情况：
		</p>
		<p>{{&nbsp;}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1、旅客手机没有打开蓝牙功能（部分手机需要同时打开WIFI）2、门锁电池没电；3、请尽量在靠近房间门锁5米范围内开锁。4、登记房间不一致。
		</p>

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>