<template>
  <div style="background-color: #fafbfc">
    <div class="header-container">
      <el-header>
        <div class="header-left">
          <img
            src="../../../assets/msxt0231010113609.png"
            style="height: 35px"
          />
        </div>
        <!-- <div class="header-right">
          <el-dropdown>
            <span class="el-dropdown-link">
              {{ form.phone }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item></el-dropdown-item>
              <el-dropdown-item>
                <div>{{ form.nc }}</div>
                <div>{{ card }}</div>
              </el-dropdown-item>
              <div
                style="
                  height: 1px;
                  width: 80%;
                  background-color: #dadada;
                  margin-right: 10%;
                  margin-left: 10%;
                "
              ></div>
              <el-dropdown-item>
                <span @click="exitLogin">退出登录</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button @click="mffbfj" type="danger" round plain class="el-button">免费发布房间</el-button>
        </div> -->
      </el-header>
    </div>
    <div
      style="
        width: 100%;
        height: calc(100% - 80px);
        overflow: hidden;
        padding-top: 80px;
      "
    >
      <div class="basic-info">
        <el-form ref="formyz" :model="formData" :rules="formRules">
          <p class="titlelx">
            <span>房源管理 /</span>
            <span> 新建房源</span>
          </p>
          <div class="tabs">
            <el-steps :space="200" :active="active" finish-status="success">
              <el-step title="房源地址"></el-step>
              <el-step title="房源概况"></el-step>
              <el-step title="房源描述"></el-step>
              <el-step title="设施服务"></el-step>
              <el-step title="房源照片"></el-step>
              <el-step title="售卖价格"></el-step>
              <el-step title="预定规则"></el-step>
            </el-steps>
          </div>
          <!-- 房源地址 -->
          <div v-if="active == 0">
            <div
              style="
                box-sizing: border-box;
                margin-top: 17px;
                padding: 28px 30px 20px 30px;
                width: 1200px;
                background: #fff;
                border-radius: 8px;
              "
            >
              <div style="position: relative">
                <div
                  style="
                    position: absolute;
                    left: -30px;
                    width: 5px;
                    height: 22px;
                    background-color: #ff5e79;
                  "
                ></div>
                <h5
                  style="
                    height: 27px;
                    line-height: 22px;
                    font-size: 16px;
                    color: #212121;
                    font-weight: 600;
                  "
                >
                  您的房源地址？
                </h5>
              </div>
              <div style="display: flex">
                <div
                  style="
                    border: solid 1px;
                    width: 180px;
                    border-radius: 10px;
                    display: flex;
                    padding: 20px;
                  "
                  @click="yydz"
                >
                  <el-radio
                    style="white-space: normal"
                    @input="yydz"
                    v-model="formData.radiofywz"
                    label="1"
                  >
                    <div style="margin-top: 10px">
                      <span style="right: 18px; left: 36px; top: 36px"
                        >选择原有地址</span
                      >
                      <div
                        style="
                          height: 22px;

                          font-size: 14px;
                          color: #757575;
                        "
                      >
                        房源将与已有地址关联
                      </div>
                    </div>
                  </el-radio>
                </div>
                <!-- <div
                  style="
                    border: solid 1px;
                    width: 180px;
                    border-radius: 10px;
                    display: flex;
                    padding: 20px;
                    margin-left: 20px;
                  "
                  @click="xwz"
                >
                  <el-radio
                    style="white-space: normal"
                    @input="xwz"
                    v-model="formData.radiofywz"
                    label="2"
                  >
                    <div style="margin-top: 10px;">
                      <span style="right: 18px; left: 36px; top: 36px"
                        >位于新位置</span
                      >
                      <div
                        style="
                          height: 22px;

                          font-size: 14px;
                          color: #757575;
                        "
                      >
                        房源将与您的新地址关联
                      </div>
                    </div>
                  </el-radio>
                </div> -->
              </div>
              <!-- 已有房源 -->
              <div v-if="yyfydz" style="position: relative">
                <div
                  style="
                    position: absolute;
                    left: -30px;
                    width: 5px;
                    height: 22px;
                    background-color: #ff5e79;
                  "
                ></div>
                <h5
                  style="
                    height: 27px;
                    line-height: 22px;
                    font-size: 16px;
                    color: #212121;
                    font-weight: 600;
                  "
                >
                  已有的房源地址
                </h5>
              </div>
              <div v-if="yyfydz" style="display: flex; flex-wrap: wrap">
                <div
                  @click="onyydzId(item.id)"
                  v-for="(item, index) in xqdzList"
                  :key="index"
                  style="
                    border: solid 1px;
                    width: 200px;
                    height: 90px;
                    border-radius: 10px;
                    margin-right: 20px;
                    margin-top: 10px;
                    padding: 15px;
                    display: flex;
                    word-wrap: break-word;
                  "
                >
                  <el-radio
                    style="white-space: normal"
                    v-model="formData.xqdz"
                    :label="item.id"
                  >
                    <div style="margin-top: 10px">
                      <span style="right: 18px; left: 36px; top: 36px">{{
                        item.xqmc
                      }}</span>
                      <span style="margin-left: 20px">房号:{{ item.fh }}</span>
                      <p
                        style="
                          height: 22px;
                          line-height: 22px;
                          font-size: 14px;
                          color: #757575;
                        "
                      >
                        {{ item.xqdz }}
                      </p>
                    </div>
                  </el-radio>
                </div>
              </div>
              <!-- 新房源 -->
              <div
                style="
                  box-sizing: border-box;
                  width: 1140px;
                  height: 531px;
                  margin-top: 30px;
                  display: flex;
                  justify-content: space-between;
                "
                v-if="xfydz"
              >
                <div
                  style="box-sizing: border-box; height: 537px; width: 530px"
                >
                  <div>
                    <div style="position: relative">
                      <div
                        style="
                          position: absolute;
                          left: -30px;
                          width: 5px;
                          height: 22px;
                          background-color: #ff5e79;
                        "
                      ></div>
                      <h5
                        style="
                          height: 27px;
                          line-height: 22px;
                          font-size: 16px;
                          color: #212121;
                          font-weight: 600;
                        "
                      >
                        详细地址
                      </h5>
                      <div style="margin-top: 12px">
                        <el-form-item prop="xxdz">
                          <el-input
                            maxlength="100"
                            type="textarea"
                            show-word-limit
                            :rows="2"
                            placeholder="请输入内容"
                            v-model="formData.xxdz"
                            :autosize="{ minRows: 5, maxRows: 4 }"
                          >
                          </el-input>
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style="position: relative">
                      <div
                        style="
                          position: absolute;
                          left: -30px;
                          width: 5px;
                          height: 22px;
                          background-color: #ff5e79;
                        "
                      ></div>
                      <h5
                        style="
                          height: 27px;
                          line-height: 22px;
                          font-size: 16px;
                          color: #212121;
                          font-weight: 600;
                        "
                      >
                        单元门牌号
                      </h5>
                      <div style="margin-top: 12px">
                        <el-form-item prop="dymph">
                          <el-input
                            maxlength="50"
                            type="textarea"
                            show-word-limit
                            :rows="2"
                            placeholder="请输入内容"
                            v-model="formData.dymph"
                            :autosize="{ minRows: 5, maxRows: 4 }"
                          >
                          </el-input>
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style="box-sizing: border-box; width: 570px; height: 537px"
                ></div>
              </div>
            </div>
          </div>
          <!-- 房源概况 -->
          <div v-if="active == 1">
            <div
              style="
                box-sizing: border-box;
                margin-top: 17px;
                padding: 28px 30px 20px 30px;
                width: 1200px;
                background: #fff;
                border-radius: 8px;
              "
            >
              <!-- <div style="position: relative">
                <div style="
                    position: absolute;
                    left: -30px;
                    width: 5px;
                    height: 22px;
                    background-color: #ff5e79;
                  "></div>
                <h5 style="
                    height: 27px;
                    line-height: 22px;
                    font-size: 16px;
                    color: #212121;
                    font-weight: 600;
                  ">
                  您的出租类型是？
                </h5>
              </div>
              <div style="display: flex">
                <div style="
                    border: solid 1px;
                    width: 235px;
                    border-radius: 10px;
                    display: flex;
                    padding: 20px;
                  ">
                  <div style="margin-top: 15px">
                    <el-radio v-model="formData.radioczlx" label="1">{{
                      ""
                    }}</el-radio>
                  </div>
                  <div>
                    <span style="right: 18px; left: 36px; top: 36px">整套出租</span>
                    <p style="
                        height: 22px;
                        line-height: 22px;
                        font-size: 14px;
                        color: #757575;
                      ">
                      客房拥有整套房屋,通常包括卧室、卫生间和厨房
                    </p>
                  </div>
                </div>
              </div> -->
              <div style="position: relative">
                <div
                  style="
                    position: absolute;
                    left: -30px;
                    width: 5px;
                    height: 22px;
                    background-color: #ff5e79;
                  "
                ></div>
                <h5
                  style="
                    height: 27px;
                    line-height: 22px;
                    font-size: 16px;
                    color: #212121;
                    font-weight: 600;
                  "
                >
                  房源信息
                </h5>
              </div>
              <div style="margin-right: 50px; margin-bottom: 22px">
                <div class="fyxxfl">房源类型</div>
                <div class="xzkcss">
                  <el-select v-model="formData.fylx" placeholder="请选择">
                    <el-option
                      v-for="item in fylxoptions"
                      :key="item.value"
                      :label="item.value"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div style="margin-right: 50px; margin-bottom: 22px">
                <div class="fyxxfl">房屋户型</div>
                <div class="xzkcss">
                  <el-select v-model="formData.wss" placeholder="请选择">
                    <el-option
                      v-for="item in fwhxoptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <!-- 厅 -->
                  <el-select
                    style="margin-left: 10px"
                    v-model="formData.kts"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in fwhxoptionT"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <!-- 厨 -->
                  <el-select
                    style="margin-left: 10px"
                    v-model="formData.cfs"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in fwhxoptionC"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <!-- 卫 -->
                  <el-select
                    style="margin-left: 10px"
                    v-model="formData.wsjs"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in fwhxoptionW"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div style="margin-right: 50px; margin-bottom: 22px">
                <div class="fyxxfl">出租面积</div>
                <div class="xzkcss">
                  <el-form-item prop="czmj">
                    <el-input
                      label-width="140px"
                      placeholder="请输入面积"
                      v-model="formData.czmj"
                    >
                      <template slot="append">m²</template>
                    </el-input>
                  </el-form-item>
                </div>
              </div>
              <div style="margin-right: 50px; margin-bottom: 22px">
                <div class="fyxxfl">床位数</div>
                <div class="xzkcss">
                  <el-input-number
                    v-model="formData.cws"
                    @change="handleChange"
                    :min="1"
                    :max="10"
                    label="描述文字"
                  ></el-input-number
                  ><span style="font-size: 18px"> 张</span>
                </div>
              </div>
              <div style="margin-right: 50px; margin-bottom: 22px">
                <div class="fyxxfl">宜居人数</div>
                <div class="xzkcss">
                  <el-input
                    label-width="140px"
                    placeholder="请输入人数"
                    v-model="formData.yjrs"
                  >
                    <template slot="append">人</template>
                  </el-input>
                  <span
                    style="font-size: 14px; color: #757575; margin-left: 10px"
                    >请填写最适宜居住的人数，而不是最多入住人数。</span
                  >
                </div>
              </div>
              <div style="position: relative">
                <div
                  style="
                    position: absolute;
                    left: -30px;
                    width: 5px;
                    height: 22px;
                    background-color: #ff5e79;
                  "
                ></div>
                <h5
                  style="
                    height: 27px;
                    line-height: 22px;
                    font-size: 16px;
                    color: #212121;
                    font-weight: 600;
                  "
                >
                  房源数量
                </h5>
              </div>
              <div style="margin-right: 50px; margin-bottom: 22px">
                <div class="fyxxfl">同类房源数</div>
                <div class="xzkcss">
                  <el-input
                    label-width="140px"
                    placeholder="请输入同类房源数"
                    v-model="formData.tlfys"
                  >
                    <template slot="append">个</template>
                  </el-input>
                  <span
                    style="font-size: 14px; color: #757575; margin-left: 10px"
                    >房客最多可预订的房源数，这些房源需户型、装修、床型、设施完全相同</span
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- 房源描述 -->
          <div v-if="active == 2">
            <div
              style="
                box-sizing: border-box;
                margin-top: 17px;
                padding: 28px 30px 20px 30px;
                width: 1200px;
                background: #fff;
                border-radius: 8px;
              "
            >
              <p
                style="
                  width: 380px;
                  font-family: PingFangSC-Regular;
                  font-size: 16px;
                  color: #757575;
                  letter-spacing: 0;
                  font-weight: 400;
                "
              >
                好的房源描述可以第一时间吸引房客
              </p>
              <!-- 标题 -->
              <div style="width: 100%; display: flex">
                <div style="width: 690px">
                  <div style="position: relative">
                    <div
                      style="
                        position: absolute;
                        left: -30px;
                        width: 5px;
                        height: 22px;
                        background-color: #ff5e79;
                      "
                    ></div>
                    <h5
                      style="
                        height: 27px;
                        line-height: 22px;
                        font-size: 16px;
                        color: #212121;
                        font-weight: 600;
                      "
                    >
                      房源标题
                    </h5>
                  </div>
                  <p
                    style="
                      font-family: PingFangSC-Regular;
                      font-size: 14px;
                      color: #757575;
                      letter-spacing: 0.01px;
                      font-weight: 400;
                      line-height: 20px;
                    "
                  >
                    精炼的介绍特点和优势，让房客在第一时间对你的房源感兴趣
                  </p>
                  <div style="margin-right: 50px">
                    <div style="margin-top: 12px">
                      <el-form-item prop="fybt">
                        <el-input
                          maxlength="60"
                          minlength="6"
                          type="textarea"
                          show-word-limit
                          :rows="2"
                          placeholder="请输入内容"
                          v-model="formData.fybt"
                          :autosize="{ minRows: 9, maxRows: 4 }"
                        >
                        </el-input>
                      </el-form-item>
                    </div>
                  </div>
                </div>
                <div
                  style="
                    width: 409px;

                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding-top: 62px;
                  "
                >
                  <div>
                    <img
                      style="width: 30px; height: 37px"
                      src="../../../assets/tsdeng.png"
                      alt=""
                    />
                  </div>
                  <span class="r1">
                    建议格式:地标+出租类型+房间形容词
                    例如:国贸双井10号线地铁阳光充沛的大床房整租。
                  </span>
                  <span class="r3">1、请不要发布联系方式</span>
                  <span class="r3"
                    >2、请不要使用使馆、旅店、招待所、浴池、计时休息、英租、法租、租界等词汇</span
                  >
                </div>
              </div>
              <!-- 房源介绍 -->
              <div style="width: 100%; display: flex">
                <div style="width: 690px">
                  <div style="position: relative">
                    <div
                      style="
                        position: absolute;
                        left: -30px;
                        width: 5px;
                        height: 22px;
                        background-color: #ff5e79;
                      "
                    ></div>
                    <h5
                      style="
                        height: 27px;
                        line-height: 22px;
                        font-size: 16px;
                        color: #212121;
                        font-weight: 600;
                      "
                    >
                      房源介绍
                    </h5>
                  </div>
                  <p
                    style="
                      font-family: PingFangSC-Regular;
                      font-size: 14px;
                      color: #757575;
                      letter-spacing: 0.01px;
                      font-weight: 400;
                      line-height: 20px;
                    "
                  >
                    房屋内装修和装饰的风格，以及主要配套设施的介绍
                  </p>
                  <div style="margin-right: 50px">
                    <div style="margin-top: 12px">
                      <el-form-item prop="fyjs">
                        <el-input
                          maxlength="2000"
                          minlength="20"
                          type="textarea"
                          show-word-limit
                          :rows="2"
                          placeholder="请输入内容"
                          v-model="formData.fyjs"
                          :autosize="{ minRows: 9, maxRows: 4 }"
                        >
                        </el-input>
                      </el-form-item>
                    </div>
                  </div>
                </div>
                <div
                  style="
                    width: 409px;

                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding-top: 62px;
                  "
                >
                  <div>
                    <img
                      style="width: 30px; height: 37px"
                      src="../../../assets/tsdeng.png"
                      alt=""
                    />
                  </div>
                  <span class="r1">
                    欧式田园小清新风格的装修，配套舒适的大床和床垫，相信您一定会舒舒服服的睡个好觉！除此之外卧室还有空调、衣柜、挂墙液晶电视、免费百兆WIFI，以及独立的卫生间哦（24小时热水是妥妥的）如果您想做饭，厨房免费提供给您，厨具调料齐备，快来大展厨艺吧！
                  </span>
                  <span class="r3">1、请不要发布联系方式</span>
                  <span class="r3"
                    >2、请不要使用使馆、旅店、招待所、浴池、计时休息、英租、法租、租界等词汇</span
                  >
                </div>
              </div>
              <!-- 房源亮点（选填） -->
              <div style="width: 100%; display: flex">
                <div style="width: 690px">
                  <div style="position: relative">
                    <div
                      style="
                        position: absolute;
                        left: -30px;
                        width: 5px;
                        height: 22px;
                        background-color: #ff5e79;
                      "
                    ></div>
                    <h5
                      style="
                        height: 27px;
                        line-height: 22px;
                        font-size: 16px;
                        color: #212121;
                        font-weight: 600;
                      "
                    >
                      房源亮点（选填）
                    </h5>
                  </div>
                  <p
                    style="
                      font-family: PingFangSC-Regular;
                      font-size: 14px;
                      color: #757575;
                      letter-spacing: 0.01px;
                      font-weight: 400;
                      line-height: 20px;
                    "
                  >
                    向房客介绍一下自己的兴趣爱好，告诉房客能体验到的不一样的特色或服务有哪些
                  </p>
                  <div style="margin-right: 50px">
                    <div style="margin-top: 12px">
                      <el-input
                        maxlength="2000"
                        type="textarea"
                        show-word-limit
                        :rows="2"
                        placeholder="请输入内容"
                        v-model="formData.fyld"
                        :autosize="{ minRows: 9, maxRows: 4 }"
                      >
                      </el-input>
                    </div>
                  </div>
                </div>
                <div
                  style="
                    width: 409px;

                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding-top: 62px;
                  "
                >
                  <div>
                    <img
                      style="width: 30px; height: 37px"
                      src="../../../assets/tsdeng.png"
                      alt=""
                    />
                  </div>
                  <span class="r1">
                    例如：除了居住之外,您可以体验家庭KTV、麻将桌、院子还可以一大堆朋友烧烤,那该是多么愉悦的周末生活呀。
                  </span>
                  <span class="r3">1、请不要发布联系方式</span>
                  <span class="r3"
                    >2、请不要使用使馆、旅店、招待所、浴池、计时休息、英租、法租、租界等词汇</span
                  >
                </div>
              </div>
              <!-- 位置周边（选填） -->
              <div style="width: 100%; display: flex">
                <div style="width: 690px">
                  <div style="position: relative">
                    <div
                      style="
                        position: absolute;
                        left: -30px;
                        width: 5px;
                        height: 22px;
                        background-color: #ff5e79;
                      "
                    ></div>
                    <h5
                      style="
                        height: 27px;
                        line-height: 22px;
                        font-size: 16px;
                        color: #212121;
                        font-weight: 600;
                      "
                    >
                      位置周边（选填）
                    </h5>
                  </div>
                  <p
                    style="
                      font-family: PingFangSC-Regular;
                      font-size: 14px;
                      color: #757575;
                      letter-spacing: 0.01px;
                      font-weight: 400;
                      line-height: 20px;
                    "
                  >
                    房源所在楼宇和小区的基本情况，以及周边商超、景点、银行、医院等配套设施等情况
                  </p>
                  <div style="margin-right: 50px">
                    <div style="margin-top: 12px">
                      <el-input
                        maxlength="4000"
                        type="textarea"
                        show-word-limit
                        :rows="2"
                        placeholder="请输入内容"
                        v-model="formData.wzzb"
                        :autosize="{ minRows: 9, maxRows: 4 }"
                      >
                      </el-input>
                    </div>
                  </div>
                </div>
                <div
                  style="
                    width: 409px;

                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding-top: 62px;
                  "
                >
                  <div>
                    <img
                      style="width: 30px; height: 37px"
                      src="../../../assets/tsdeng.png"
                      alt=""
                    />
                  </div>
                  <span class="r1">
                    例如：
                    位置：位于安定门外大街，从安定门地铁站A口5分钟即到。楼下还有27路、117路公交线路，十分方便。
                    周边：周边设施齐全,餐饮设施有肯德基、永和大王、老上海城隍庙小吃等,金融机构有建设银行、中国银行等,医疗服务有解放军总医院。
                  </span>
                  <span class="r3">1、请不要发布联系方式</span>
                  <span class="r3"
                    >2、请不要使用使馆、旅店、招待所、浴池、计时休息、英租、法租、租界等词汇</span
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- 设施服务 -->
          <div v-if="active == 3">
            <div
              style="
                box-sizing: border-box;
                margin-top: 17px;
                padding: 28px 30px 20px 30px;
                width: 1200px;
                background: #fff;
                border-radius: 8px;
              "
            >
              <p
                style="
                  width: 380px;
                  font-family: PingFangSC-Regular;
                  font-size: 16px;
                  color: #757575;
                  letter-spacing: 0;
                  font-weight: 400;
                "
              >
                您是否提供以下设施和服务？
              </p>
              <el-form
                ref="formhxss"
                :model="formDatahxss"
                :rules="formRuleshxss"
              >
                <!-- 核心设施 -->
                <div>
                  <div style="position: relative">
                    <div
                      style="
                        position: absolute;
                        left: -30px;
                        width: 5px;
                        height: 22px;
                        background-color: #ff5e79;
                      "
                    ></div>
                    <h5
                      style="
                        height: 27px;
                        line-height: 22px;
                        font-size: 16px;
                        color: #212121;
                        font-weight: 600;
                      "
                    >
                      核心设施 （必选）
                    </h5>
                  </div>
                  <div
                    style="
                      font-family: PingFangSC-Regular;
                      font-size: 14px;
                      color: #757575;
                      letter-spacing: 0.01px;
                      line-height: 20px;
                      font-weight: 400;
                      white-space: pre-line;
                    "
                  >
                    房客在预订时很关心这些设施服务，准确填写会提升房客预订意愿和满意度。
                  </div>
                  <div>
                    <div style="margin-right: 50px; margin-top: 22px">
                      <div class="fyxxfl">停车位</div>
                      <div class="xzkcss">
                        <el-form-item prop="tcw">
                          <el-radio v-model="formDatahxss.tcw" label="无" border
                            >无</el-radio
                          >
                          <el-radio v-model="formDatahxss.tcw" label="有" border
                            >有</el-radio
                          >
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style="margin-right: 50px; margin-top: 22px">
                      <div class="fyxxfl">电梯</div>
                      <div class="xzkcss">
                        <el-form-item prop="dt">
                          <el-radio v-model="formDatahxss.dt" label="无" border
                            >无</el-radio
                          >
                          <el-radio v-model="formDatahxss.dt" label="有" border
                            >有</el-radio
                          >
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style="margin-right: 50px; margin-top: 22px">
                      <div class="fyxxfl">无线网络</div>
                      <div class="xzkcss">
                        <el-form-item prop="wxwl">
                          <el-radio
                            v-model="formDatahxss.wxwl"
                            label="无"
                            border
                            >无</el-radio
                          >
                          <el-radio
                            v-model="formDatahxss.wxwl"
                            label="有"
                            border
                            >有</el-radio
                          >
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style="margin-right: 50px; margin-top: 22px">
                      <div class="fyxxfl">窗户</div>
                      <div class="xzkcss">
                        <el-form-item prop="ch">
                          <el-radio v-model="formDatahxss.ch" label="无" border
                            >无</el-radio
                          >
                          <el-radio v-model="formDatahxss.ch" label="有" border
                            >有</el-radio
                          >
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style="margin-right: 50px; margin-top: 22px">
                      <div class="fyxxfl">床品更换</div>
                      <div class="xzkcss">
                        <el-form-item prop="cpgh">
                          <el-radio
                            v-model="formDatahxss.cpgh"
                            label="每天换床品"
                            border
                            >每天换床品</el-radio
                          >
                          <el-radio
                            v-model="formDatahxss.cpgh"
                            label="换客换床品"
                            border
                            >换客换床品</el-radio
                          >
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style="margin-right: 50px; margin-top: 22px">
                      <div class="fyxxfl">拖鞋</div>
                      <div class="xzkcss">
                        <el-form-item prop="tx">
                          <el-radio v-model="formDatahxss.tx" label="无" border
                            >无</el-radio
                          >
                          <el-radio v-model="formDatahxss.tx" label="有" border
                            >有</el-radio
                          >
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style="margin-right: 50px; margin-top: 22px">
                      <div class="fyxxfl">空调</div>
                      <div class="xzkcss">
                        <el-form-item prop="kt">
                          <el-radio v-model="formDatahxss.kt" label="无" border
                            >无</el-radio
                          >
                          <el-radio v-model="formDatahxss.kt" label="有" border
                            >有</el-radio
                          >
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style="margin-right: 50px; margin-top: 22px">
                      <div class="fyxxfl">有线网络</div>
                      <div class="xzkcss">
                        <el-form-item prop="yxwl">
                          <el-radio
                            v-model="formDatahxss.yxwl"
                            label="无"
                            border
                            >无</el-radio
                          >
                          <el-radio
                            v-model="formDatahxss.yxwl"
                            label="有"
                            border
                            >有</el-radio
                          >
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style="margin-right: 50px; margin-top: 22px">
                      <div class="fyxxfl">充电桩</div>
                      <div class="xzkcss">
                        <el-form-item prop="cdz">
                          <el-radio v-model="formDatahxss.cdz" label="无" border
                            >无</el-radio
                          >
                          <el-radio v-model="formDatahxss.cdz" label="有" border
                            >有</el-radio
                          >
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style="margin-right: 50px; margin-top: 22px">
                      <div class="fyxxfl">付费停车位</div>
                      <div class="xzkcss">
                        <el-form-item prop="fftcw">
                          <el-radio
                            v-model="formDatahxss.fftcw"
                            label="无"
                            border
                            >无</el-radio
                          >
                          <el-radio
                            v-model="formDatahxss.fftcw"
                            label="有"
                            border
                            >有</el-radio
                          >
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style="margin-right: 50px; margin-top: 22px">
                      <div class="fyxxfl">免费停车位</div>
                      <div class="xzkcss">
                        <el-form-item prop="mftcw">
                          <el-radio
                            v-model="formDatahxss.mftcw"
                            label="无"
                            border
                            >无</el-radio
                          >
                          <el-radio
                            v-model="formDatahxss.mftcw"
                            label="有"
                            border
                            >有</el-radio
                          >
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style="margin-right: 50px; margin-top: 22px">
                      <div class="fyxxfl">智能门锁</div>
                      <div class="xzkcss">
                        <el-form-item prop="znms">
                          <el-radio
                            v-model="formDatahxss.znms"
                            label="无"
                            border
                            >无</el-radio
                          >
                          <el-radio
                            v-model="formDatahxss.znms"
                            label="有"
                            border
                            >有</el-radio
                          >
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style="margin-right: 50px; margin-top: 22px">
                      <div class="fyxxfl">饮水设备</div>
                      <div class="xzkcss">
                        <el-form-item prop="yssb">
                          <el-radio
                            v-model="formDatahxss.yssb"
                            label="无"
                            border
                            >无</el-radio
                          >
                          <el-radio
                            v-model="formDatahxss.yssb"
                            label="有"
                            border
                            >有</el-radio
                          >
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style="margin-right: 50px; margin-top: 22px">
                      <div class="fyxxfl">接机服务</div>
                      <div class="xzkcss">
                        <el-form-item prop="jjfw">
                          <el-radio
                            v-model="formDatahxss.jjfw"
                            label="无"
                            border
                            >无</el-radio
                          >
                          <el-radio
                            v-model="formDatahxss.jjfw"
                            label="有"
                            border
                            >有</el-radio
                          >
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style="margin-right: 50px; margin-top: 22px">
                      <div class="fyxxfl">接站服务</div>
                      <div class="xzkcss">
                        <el-form-item prop="jzfw">
                          <el-radio
                            v-model="formDatahxss.jzfw"
                            label="无"
                            border
                            >无</el-radio
                          >
                          <el-radio
                            v-model="formDatahxss.jzfw"
                            label="有"
                            border
                            >有</el-radio
                          >
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style="margin-right: 50px; margin-top: 22px">
                      <div class="fyxxfl">淋浴房</div>
                      <div class="xzkcss">
                        <el-form-item prop="lyf">
                          <el-radio v-model="formDatahxss.lyf" label="无" border
                            >无</el-radio
                          >
                          <el-radio v-model="formDatahxss.lyf" label="有" border
                            >有</el-radio
                          >
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 卫浴设施 -->
                <!-- <div>
                  <div
                    v-for="item in sslist"
                    :key="item"
                    style="position: relative"
                  >
                    <div
                      style="
                        position: absolute;
                        left: -30px;
                        width: 5px;
                        height: 22px;
                        background-color: #ff5e79;
                      "
                    ></div>
                    <h5
                      style="
                        height: 27px;
                        line-height: 22px;
                        font-size: 16px;
                        color: #212121;
                        font-weight: 600;
                      "
                    >
                      {{ item.name }}
                    </h5>
                    <div>
                      <el-checkbox-group v-model="formDatahxss.wyss">
                        <el-checkbox-button
                          style="
                            margin-left: 10px;
                            margin-top: 5px;
                            border: solid 1px #e0e0e0;
                            border-radius: 5px;
                          "
                          v-for="item1 in item.children"
                          :label="item1"
                          :key="item1"
                          >{{ item1.name }}</el-checkbox-button
                        >
                      </el-checkbox-group>
                    </div>
                  </div>
                </div> -->
                <div>
                  <div style="position: relative">
                    <div style="
                      position: absolute;
                      left: -30px;
                      width: 5px;
                      height: 22px;
                      background-color: #ff5e79;
                    "></div>
                    <h5 style="
                      height: 27px;
                      line-height: 22px;
                      font-size: 16px;
                      color: #212121;
                      font-weight: 600;
                    ">
                      卫浴设施
                    </h5>
                    <div>
                      <el-checkbox-group v-model="formDatahxss.wyss">
                        <el-checkbox-button style="
                          margin-left: 10px;
                          margin-top: 5px;
                          border: solid 1px #e0e0e0;
                          border-radius: 5px;
                        " v-for="city in wyss1" :label="city.name" :key="city.name">{{ city.name }}</el-checkbox-button>
                      </el-checkbox-group>
                    </div>
                  </div>
                  <div style="position: relative">
                    <div style="
                      position: absolute;
                      left: -30px;
                      width: 5px;
                      height: 22px;
                      background-color: #ff5e79;
                    "></div>
                    <h5 style="
                      height: 27px;
                      line-height: 22px;
                      font-size: 16px;
                      color: #212121;
                      font-weight: 600;
                    ">
                      安全保障
                    </h5>
                    <div>
                      <el-checkbox-group v-model="formDatahxss.aqbz">
                        <el-checkbox-button style="
                          margin-left: 10px;
                          margin-top: 5px;
                          border: solid 1px #e0e0e0;
                          border-radius: 5px;
                        " v-for="city in aqbz1" :label="city.name" :key="city.name">{{ city.name }}</el-checkbox-button>
                      </el-checkbox-group>
                    </div>
                  </div>
                  <div style="position: relative">
                    <div style="
                      position: absolute;
                      left: -30px;
                      width: 5px;
                      height: 22px;
                      background-color: #ff5e79;
                    "></div>
                    <h5 style="
                      height: 27px;
                      line-height: 22px;
                      font-size: 16px;
                      color: #212121;
                      font-weight: 600;
                    ">
                      入住服务
                    </h5>
                    <div>
                      <el-checkbox-group v-model="formDatahxss.rzfw">
                        <el-checkbox-button style="
                          margin-left: 10px;
                          margin-top: 5px;
                          border: solid 1px #e0e0e0;
                          border-radius: 5px;
                        " v-for="city in rzfw1" :label="city.name" :key="city.name">{{ city.name }}</el-checkbox-button>
                      </el-checkbox-group>
                    </div>
                  </div>
                  <div style="position: relative">
                    <div style="
                      position: absolute;
                      left: -30px;
                      width: 5px;
                      height: 22px;
                      background-color: #ff5e79;
                    "></div>
                    <h5 style="
                      height: 27px;
                      line-height: 22px;
                      font-size: 16px;
                      color: #212121;
                      font-weight: 600;
                    ">
                      居家设施
                    </h5>
                    <div>
                      <el-checkbox-group v-model="formDatahxss.jjss">
                        <el-checkbox-button style="
                          margin-left: 10px;
                          margin-top: 5px;
                          border: solid 1px #e0e0e0;
                          border-radius: 5px;
                        " v-for="city in jjss1" :label="city.name" :key="city.name">{{ city.name }}</el-checkbox-button>
                      </el-checkbox-group>
                    </div>
                  </div>
                  <div style="position: relative">
                    <div style="
                      position: absolute;
                      left: -30px;
                      width: 5px;
                      height: 22px;
                      background-color: #ff5e79;
                    "></div>
                    <h5 style="
                      height: 27px;
                      line-height: 22px;
                      font-size: 16px;
                      color: #212121;
                      font-weight: 600;
                    ">
                      餐厨设施
                    </h5>
                    <div>
                      <el-checkbox-group v-model="formDatahxss.ccss">
                        <el-checkbox-button style="
                          margin-left: 10px;
                          margin-top: 5px;
                          border: solid 1px #e0e0e0;
                          border-radius: 5px;
                        " v-for="city in ccss1" :label="city.name" :key="city.name">{{ city.name }}</el-checkbox-button>
                      </el-checkbox-group>
                    </div>
                  </div>
                  <div style="position: relative">
                    <div style="
                      position: absolute;
                      left: -30px;
                      width: 5px;
                      height: 22px;
                      background-color: #ff5e79;
                    "></div>
                    <h5 style="
                      height: 27px;
                      line-height: 22px;
                      font-size: 16px;
                      color: #212121;
                      font-weight: 600;
                    ">
                      休闲娱乐
                    </h5>
                    <div>
                      <el-checkbox-group v-model="formDatahxss.xxyl">
                        <el-checkbox-button style="
                          margin-left: 10px;
                          margin-top: 5px;
                          border: solid 1px #e0e0e0;
                          border-radius: 5px;
                        " v-for="city in xxyl1" :label="city.name" :key="city.name">{{ city.name }}</el-checkbox-button>
                      </el-checkbox-group>
                    </div>
                  </div>
                  <div style="position: relative">
                    <div style="
                      position: absolute;
                      left: -30px;
                      width: 5px;
                      height: 22px;
                      background-color: #ff5e79;
                    "></div>
                    <h5 style="
                      height: 27px;
                      line-height: 22px;
                      font-size: 16px;
                      color: #212121;
                      font-weight: 600;
                    ">
                      特别设施
                    </h5>
                    <div>
                      <el-checkbox-group v-model="formDatahxss.tbss">
                        <el-checkbox-button style="
                          margin-left: 10px;
                          margin-top: 5px;
                          border: solid 1px #e0e0e0;
                          border-radius: 5px;
                        " v-for="city in tbss1" :label="city.name" :key="city.name">{{ city.name }}</el-checkbox-button>
                      </el-checkbox-group>
                    </div>
                  </div>
                  <div style="position: relative">
                    <div style="
                      position: absolute;
                      left: -30px;
                      width: 5px;
                      height: 22px;
                      background-color: #ff5e79;
                    "></div>
                    <h5 style="
                      height: 27px;
                      line-height: 22px;
                      font-size: 16px;
                      color: #212121;
                      font-weight: 600;
                    ">
                      儿童设施
                    </h5>
                    <div>
                      <el-checkbox-group v-model="formDatahxss.etss">
                        <el-checkbox-button style="
                          margin-left: 10px;
                          margin-top: 5px;
                          border: solid 1px #e0e0e0;
                          border-radius: 5px;
                        " v-for="city in etss1" :label="city.name" :key="city.name">{{ city.name }}</el-checkbox-button>
                      </el-checkbox-group>
                    </div>
                  </div>
                  <div style="position: relative">
                    <div style="
                      position: absolute;
                      left: -30px;
                      width: 5px;
                      height: 22px;
                      background-color: #ff5e79;
                    "></div>
                    <h5 style="
                      height: 27px;
                      line-height: 22px;
                      font-size: 16px;
                      color: #212121;
                      font-weight: 600;
                    ">
                      周边500米
                    </h5>
                    <div>
                      <el-checkbox-group v-model="formDatahxss.zb">
                        <el-checkbox-button style="
                          margin-left: 10px;
                          margin-top: 5px;
                          border: solid 1px #e0e0e0;
                          border-radius: 5px;
                        " v-for="city in zb1" :label="city.name" :key="city.name">{{ city.name }}</el-checkbox-button>
                      </el-checkbox-group>
                    </div>
                  </div>
                </div>
              </el-form>
            </div>
          </div>
          <!-- 房源照片 -->
          <div v-if="active == 4">
            <div
              style="
                box-sizing: border-box;
                margin-top: 17px;
                padding: 28px 30px 20px 30px;
                width: 1200px;
                background: #fff;
                border-radius: 8px;
              "
            >
              <div>
                <div
                  style="
                    height: 50px;
                    line-height: 28px;
                    font-size: 16px;
                    color: #757575;
                    letter-spacing: 1.11px;
                  "
                >
                  清晰精美的图片可以第一时间吸引房客
                </div>
                <div style="padding: 18px 18px 18px 0; box-sizing: border-box">
                  <div class="tips"></div>
                  <div style="margin-left: 50px">
                    <div
                      style="line-height: 22px; font-size: 14px; color: #757575"
                    >
                      1、请至少上传5张精美图片
                    </div>
                    <div
                      style="line-height: 22px; font-size: 14px; color: #757575"
                    >
                      2、高质量无水印照片可提升订单量,同时避免客诉
                    </div>
                  </div>
                </div>
              </div>
              <!-- 上传图片 -->
              <div>
                <div style="position: relative">
                  <div
                    style="
                      position: absolute;
                      left: -30px;
                      width: 5px;
                      height: 22px;
                      background-color: #ff5e79;
                    "
                  ></div>
                  <h5 style="font-size: 16px; color: #212121; font-weight: 600">
                    卧室
                  </h5>
                </div>
                <div class="imgts">
                  至少上传1张，建议上传5-10张，需展示完整床铺
                </div>
                <div style="display: flex">
                  <el-upload
                    action="http://192.168.100.166:9910/platform/pub/file/upload/imageFw"
                    list-type="picture-card"
                    :on-success="handlePictureCardPreviewws"
                    :on-remove="handleRemovews"
                    :file-list="wsimgList"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                </div>
              </div>
              <!-- 客厅 -->
              <div>
                <div style="position: relative">
                  <div
                    style="
                      position: absolute;
                      left: -30px;
                      width: 5px;
                      height: 22px;
                      background-color: #ff5e79;
                    "
                  ></div>
                  <h5 style="font-size: 16px; color: #212121; font-weight: 600">
                    客厅
                  </h5>
                </div>
                <div class="imgts">建议上传5-10张，尽量展示全景</div>
                <div style="display: flex">
                  <el-upload
                    action="http://192.168.100.166:9910/platform/pub/file/upload/imageFw"
                    list-type="picture-card"
                    :on-success="handlePictureCardPreviewkt"
                    :on-remove="handleRemovekt"
                    :file-list="ktimgList"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                </div>
              </div>
              <!-- 卫生间 -->
              <div>
                <div style="position: relative">
                  <div
                    style="
                      position: absolute;
                      left: -30px;
                      width: 5px;
                      height: 22px;
                      background-color: #ff5e79;
                    "
                  ></div>
                  <h5 style="font-size: 16px; color: #212121; font-weight: 600">
                    卫生间
                  </h5>
                </div>
                <div class="imgts">建议上传5-10张，需展示卫浴设施</div>
                <div style="display: flex">
                  <el-upload
                    action="http://192.168.100.166:9910/platform/pub/file/upload/imageFw"
                    list-type="picture-card"
                    :on-success="handlePictureCardPreviewwsj"
                    :on-remove="handleRemovewsj"
                    :file-list="wsjimgList"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                </div>
              </div>
              <!-- 厨房  -->
              <div>
                <div style="position: relative">
                  <div
                    style="
                      position: absolute;
                      left: -30px;
                      width: 5px;
                      height: 22px;
                      background-color: #ff5e79;
                    "
                  ></div>
                  <h5 style="font-size: 16px; color: #212121; font-weight: 600">
                    厨房
                  </h5>
                </div>
                <div class="imgts">建议上传5-10张，需展示厨房设施</div>
                <div style="display: flex">
                  <el-upload
                    action="http://192.168.100.166:9910/platform/pub/file/upload/imageFw"
                    list-type="picture-card"
                    :on-success="handlePictureCardPreviewcf"
                    :on-remove="handleRemovecf"
                    :file-list="cfimgList"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                </div>
              </div>
              <!-- 其他  -->
              <div>
                <div style="position: relative">
                  <div
                    style="
                      position: absolute;
                      left: -30px;
                      width: 5px;
                      height: 22px;
                      background-color: #ff5e79;
                    "
                  ></div>
                  <h5 style="font-size: 16px; color: #212121; font-weight: 600">
                    其他
                  </h5>
                </div>
                <div class="imgts">
                  建议上传房源周边环境或者房源其他细节照片
                </div>
                <div style="display: flex">
                  <el-upload
                    action="http://192.168.100.166:9910/platform/pub/file/upload/imageFw"
                    list-type="picture-card"
                    :on-success="handlePictureCardPreviewqt"
                    :on-remove="handleRemoveqt"
                    :file-list="qtimgList"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                </div>
              </div>
              <!-- 封面  -->
              <div>
                <div style="position: relative">
                  <div
                    style="
                      position: absolute;
                      left: -30px;
                      width: 5px;
                      height: 22px;
                      background-color: #ff5e79;
                    "
                  ></div>
                  <h5 style="font-size: 16px; color: #212121; font-weight: 600">
                    封面
                  </h5>
                </div>
                <div class="imgts">请上传1张横图,建议使用客厅或卧室横图。</div>
                <div>
                  <!-- <el-upload
                    class="avatar-uploader"
                    action="http://192.168.100.166:9910/platform/pub/file/upload/imageFw"
                    :show-file-list="false"
                    :on-success="handleUploadSuccessFmt"
                  >
                    <img
                      v-if="formData.fmt && imageUrl1 == ''"
                      :src="'http://hjrvqj.natappfree.cc/msxcx' + formData.fmt"
                      class="avatar"
                    />
                    <img
                      v-else-if="imageUrl1 != ''"
                      :src="imageUrl1"
                      class="avatar"
                    />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload> -->
                  <el-upload
                    action="http://192.168.100.166:9910/platform/pub/file/upload/imageFw"
                    list-type="picture-card"
                    :on-success="handlePictureCardPreviewfm"
                    :on-remove="handleRemovefm"
                    :file-list="fmimgList"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                </div>
              </div>
            </div>
          </div>
          <!-- 售卖价格 -->
          <div v-if="active == 5">
            <div
              style="
                box-sizing: border-box;
                margin-top: 17px;
                padding: 28px 30px 20px 30px;
                width: 1200px;
                background: #fff;
                border-radius: 8px;
              "
            >
              <div
                style="
                  height: 50px;
                  line-height: 28px;
                  font-size: 16px;
                  color: #757575;
                  letter-spacing: 1.11px;
                "
              >
                请为您的房源设置预订规则
              </div>
              <div style="position: relative">
                <div
                  style="
                    position: absolute;
                    left: -30px;
                    width: 5px;
                    height: 22px;
                    background-color: #ff5e79;
                  "
                ></div>
                <h5
                  style="
                    height: 27px;
                    line-height: 22px;
                    font-size: 16px;
                    color: #212121;
                    font-weight: 600;
                  "
                >
                  售卖价格
                </h5>
              </div>
              <div
                style="
                  font-family: PingFangSC-Regular;
                  font-size: 14px;
                  color: #757575;
                  letter-spacing: 0.01px;
                  line-height: 20px;
                  font-weight: 400;
                  white-space: pre-line;
                "
              >
                日价的10%将作为服务费，请酌情考虑。
              </div>
              <div>
                <div style="margin-right: 50px; margin-top: 22px">
                  <div class="fyxxfl">周日-周四价格：</div>
                  <div class="xzkcss">
                    <el-form-item prop="prje">
                      <el-input
                        label-width="140px"
                        placeholder="请输入"
                        v-model="formData.prje"
                      >
                        <template slot="append">元/每晚</template>
                      </el-input>
                    </el-form-item>
                  </div>
                </div>
              </div>
              <div>
                <div style="margin-right: 50px; margin-top: 22px">
                  <div class="fyxxfl">周五-周六价格：</div>
                  <div class="xzkcss">
                    <el-form-item prop="qtje">
                      <el-input
                        label-width="140px"
                        placeholder="请输入"
                        v-model="formData.qtje"
                      >
                        <template slot="append">元/每晚</template>
                      </el-input>
                    </el-form-item>
                  </div>
                </div>
              </div>
              <div>
                <div style="margin-right: 50px; margin-top: 22px">
                  <div class="fyxxfl">节假日价格：</div>
                  <div class="xzkcss">
                    <el-form-item prop="jjrje">
                      <el-input
                        label-width="140px"
                        placeholder="请输入"
                        v-model="formData.jjrje"
                      >
                        <template slot="append">元/每晚</template>
                      </el-input>
                    </el-form-item>
                  </div>
                </div>
              </div>
              <div style="position: relative">
                <div
                  style="
                    position: absolute;
                    left: -30px;
                    width: 5px;
                    height: 22px;
                    background-color: #ff5e79;
                  "
                ></div>
                <h5
                  style="
                    height: 27px;
                    line-height: 22px;
                    font-size: 16px;
                    color: #212121;
                    font-weight: 600;
                  "
                >
                  加客费
                </h5>
              </div>
              <div>
                <div style="margin-right: 50px; margin-top: 22px">
                  <div class="fyxxfl">是否允许加客：</div>
                  <div class="xzkcss">
                    <el-radio
                      @input="jkfradios"
                      v-model="formData.sfyxjk"
                      label="是"
                      border
                      >是</el-radio
                    >
                    <el-radio
                      @input="jkfradiof"
                      v-model="formData.sfyxjk"
                      label="否"
                      border
                      >否</el-radio
                    >
                  </div>
                </div>
              </div>
              <div v-if="jkfshow">
                <div style="margin-right: 50px; margin-top: 22px">
                  <div class="fyxxfl">最多加客人数：</div>
                  <div class="xzkcss">
                    <el-select v-model="formData.jkrs" placeholder="请选择">
                      <el-option
                        v-for="item in jkrsoptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
                <div style="margin-right: 50px; margin-top: 22px">
                  <div class="fyxxfl">加客费：</div>
                  <div class="xzkcss">
                    <el-input
                      label-width="140px"
                      placeholder="请输入"
                      v-model="formData.jkf"
                    >
                      <template slot="append">元/每晚</template>
                    </el-input>
                  </div>
                </div>
                <div style="margin-right: 50px; margin-top: 22px">
                  <div class="fyxxfl">加客费描述：</div>
                  <div class="xzkcss">
                    <el-input
                      maxlength="60"
                      type="textarea"
                      show-word-limit
                      :rows="2"
                      placeholder="对税费、电费、燃气费等其他费用有其他要求，请进行描述 (选填)"
                      v-model="formData.jkfms"
                      :autosize="{ minRows: 9, maxRows: 2 }"
                    >
                    </el-input>
                  </div>
                </div>
              </div>
              <div style="position: relative">
                <div
                  style="
                    position: absolute;
                    left: -30px;
                    width: 5px;
                    height: 22px;
                    background-color: #ff5e79;
                  "
                ></div>
                <h5
                  style="
                    height: 27px;
                    line-height: 22px;
                    font-size: 16px;
                    color: #212121;
                    font-weight: 600;
                  "
                >
                  押金
                </h5>
              </div>
              <div>
                <div style="margin-right: 50px; margin-top: 22px">
                  <div class="fyxxfl">押金:</div>
                  <div class="xzkcss">
                    <el-radio
                      @input="yjradios"
                      v-model="formData.radio1yj"
                      label="是"
                      border
                      >是</el-radio
                    >
                    <el-radio
                      @input="yjradiof"
                      v-model="formData.radio1yj"
                      label="否"
                      border
                      >否</el-radio
                    >
                  </div>
                </div>
                <div
                  v-if="yjjeshow"
                  style="margin-right: 50px; margin-top: 22px"
                >
                  <div class="fyxxfl">押金金额：</div>
                  <div class="xzkcss">
                    <el-input
                      label-width="140px"
                      placeholder="请输入"
                      v-model="formData.yj"
                    >
                      <template slot="append">元</template>
                    </el-input>
                    <span
                      style="margin-left: 10px; font-size: 14px; color: #757575"
                      >请合理设置押金金额，过高押金可能会降低房客预订意愿</span
                    >
                  </div>
                </div>
              </div>
              <div style="position: relative">
                <div
                  style="
                    position: absolute;
                    left: -30px;
                    width: 5px;
                    height: 22px;
                    background-color: #ff5e79;
                  "
                ></div>
                <h5
                  style="
                    height: 27px;
                    line-height: 22px;
                    font-size: 16px;
                    color: #212121;
                    font-weight: 600;
                  "
                >
                  其他额外费用
                </h5>
              </div>
              <div style="margin-right: 50px; margin-top: 22px">
                <div class="fyxxfl">费用说明：</div>
                <div class="xzkcss">
                  <el-input
                    maxlength="2000"
                    type="textarea"
                    show-word-limit
                    :rows="2"
                    placeholder="对税费、电费、燃气费等其他费用有其他要求，请进行描述 (选填)"
                    v-model="formData.qtewfy"
                    :autosize="{ minRows: 9, maxRows: 2 }"
                  >
                  </el-input>
                </div>
              </div>
              <div style="position: relative">
                <div
                  style="
                    position: absolute;
                    left: -30px;
                    width: 5px;
                    height: 22px;
                    background-color: #ff5e79;
                  "
                ></div>
                <h5
                  style="
                    height: 27px;
                    line-height: 22px;
                    font-size: 16px;
                    color: #212121;
                    font-weight: 600;
                  "
                >
                  退订规则
                </h5>
              </div>
              <div style="margin-right: 50px; margin-top: 22px">
                <div class="fyxxfl">退订规则：</div>
                <div class="xzkcss">
                  <div style="float: left; width: 280px">
                    <div
                      style="
                        display: inline-block;
                        line-height: 1;
                        vertical-align: middle;
                        font-size: 0;
                      "
                    >
                      <div style="display: flex">
                        <div
                          style="
                            border: solid 1px;
                            width: 235px;
                            border-radius: 10px;
                            display: flex;
                            padding: 15px;
                          "
                        >
                          <div style="margin-top: 15px">
                            <el-radio
                              @input="kstd"
                              v-model="formData.tdgz"
                              label="宽松"
                              >{{ "" }}</el-radio
                            >
                          </div>
                          <div>
                            <div style="font-size: 16px; color: #000">
                              宽松
                              <span
                                style="
                                  padding: 2px 6px;
                                  background-color: rgba(255, 94, 121, 0.1);
                                  border-radius: 2px;
                                  color: #ff5e79;
                                  font-size: 12px;
                                  margin-left: 10px;
                                "
                                >推荐</span
                              >
                            </div>
                            <div
                              style="
                                margin-top: 5px;
                                font-size: 14px;
                                color: #757575;
                              "
                            >
                              入住前1天14:00后取消需支付违约金
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style="
                        margin-top: 10px;
                        display: inline-block;
                        line-height: 1;
                        vertical-align: middle;
                        font-size: 0;
                      "
                    >
                      <div style="display: flex">
                        <div
                          style="
                            border: solid 1px;
                            width: 235px;
                            border-radius: 10px;
                            display: flex;
                            padding: 15px;
                          "
                        >
                          <div style="margin-top: 15px">
                            <el-radio
                              @input="kstd"
                              v-model="formData.tdgz"
                              label="中等"
                              >{{ "" }}</el-radio
                            >
                          </div>
                          <div>
                            <div style="font-size: 16px; color: #000">中等</div>
                            <div
                              style="
                                margin-top: 5px;
                                font-size: 14px;
                                color: #757575;
                              "
                            >
                              入住前3天14:00后取消需支付违约金
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style="
                        margin-top: 10px;
                        display: inline-block;
                        line-height: 1;
                        vertical-align: middle;
                        font-size: 0;
                      "
                    >
                      <div style="display: flex">
                        <div
                          style="
                            border: solid 1px;
                            width: 235px;
                            border-radius: 10px;
                            display: flex;
                            padding: 15px;
                          "
                        >
                          <div style="margin-top: 15px">
                            <el-radio
                              @input="kstd"
                              v-model="formData.tdgz"
                              label="严格"
                              >{{ "" }}</el-radio
                            >
                          </div>
                          <div>
                            <div style="font-size: 16px; color: #000">严格</div>
                            <div
                              style="
                                margin-top: 5px;
                                font-size: 14px;
                                color: #757575;
                              "
                            >
                              入住前7天14:00后取消需支付违约金
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style="
                      position: relative;
                      margin-left: 20px;
                      display: inline-block;
                      width: 380px;
                      height: 255px;
                      background-color: #fff;
                      border-radius: 8px;
                      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
                    "
                  >
                    <div style="padding: 30px">
                      <div style="overflow: hidden">
                        <div style="float: left; width: 12px">
                          <div class="clock-icon"></div>
                          <div
                            style="
                              width: 1px;
                              height: 50px;
                              margin-left: 5px;
                              background-color: #212121;
                            "
                          ></div>
                          <div class="clock-icon"></div>
                        </div>
                        <div
                          v-if="this.formData.tdgz == '宽松'"
                          style="float: left"
                        >
                          <div style="margin: 0 0 30px 10px; line-height: 22px">
                            <div style="font-size: 16px; color: #212121">
                              入住前 1 天 14:00 前取消
                            </div>
                            <div style="font-size: 14px; color: #757575">
                              房客无需支付违约金
                            </div>
                          </div>
                          <div style="margin: 0 0 30px 10px; line-height: 22px">
                            <div style="font-size: 16px; color: #212121">
                              入住前 1 天 14:00 前取消
                            </div>
                            <div style="font-size: 14px; color: #757575">
                              房客无需支付违约金
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="this.formData.tdgz == '中等'"
                          style="float: left"
                        >
                          <div style="margin: 0 0 30px 10px; line-height: 22px">
                            <div style="font-size: 16px; color: #212121">
                              入住前 3 天 14:00 前取消
                            </div>
                            <div style="font-size: 14px; color: #757575">
                              房客无需支付违约金
                            </div>
                          </div>
                          <div style="margin: 0 0 30px 10px; line-height: 22px">
                            <div style="font-size: 16px; color: #212121">
                              入住前 3 天 14:00 前取消
                            </div>
                            <div style="font-size: 14px; color: #757575">
                              房客无需支付违约金
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="this.formData.tdgz == '严格'"
                          style="float: left"
                        >
                          <div style="margin: 0 0 30px 10px; line-height: 22px">
                            <div style="font-size: 16px; color: #212121">
                              入住前 7 天 14:00 前取消
                            </div>
                            <div style="font-size: 14px; color: #757575">
                              房客无需支付违约金
                            </div>
                          </div>
                          <div style="margin: 0 0 30px 10px; line-height: 22px">
                            <div style="font-size: 16px; color: #212121">
                              入住前 7 天 14:00 前取消
                            </div>
                            <div style="font-size: 14px; color: #757575">
                              房客无需支付违约金
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style="
                          color: #8b2347;
                          font-size: 14px;
                          padding-top: 18px;
                          border-top: 1px solid #e0e0e0;
                        "
                      >
                        房客预订成功后15分钟内取消无需支付违约金
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 预定规则 -->
          <div v-if="active == 6">
            <div
              style="
                box-sizing: border-box;
                margin-top: 17px;
                padding: 28px 30px 20px 30px;
                width: 1200px;
                background: #fff;
                border-radius: 8px;
              "
            >
              <div>
                <div
                  style="
                    height: 50px;
                    line-height: 28px;
                    font-size: 16px;
                    color: #757575;
                    letter-spacing: 1.11px;
                  "
                >
                  请为您的房源设置预订规则
                </div>
              </div>
              <div style="position: relative">
                <div
                  style="
                    position: absolute;
                    left: -30px;
                    width: 5px;
                    height: 22px;
                    background-color: #ff5e79;
                  "
                ></div>
                <h5
                  style="
                    height: 27px;
                    line-height: 22px;
                    font-size: 16px;
                    color: #212121;
                    font-weight: 600;
                  "
                >
                  预订要求
                </h5>
              </div>

              <div>
                <div style="margin-right: 50px; margin-top: 22px">
                  <div class="fyxxfl">最少入住天数:</div>
                  <div class="xzkcss">
                    <el-input
                      label-width="140px"
                      placeholder="请输入"
                      v-model="formData.zsrzts"
                    >
                      <template slot="append">天</template>
                    </el-input>
                  </div>
                </div>
                <div style="margin-right: 50px; margin-top: 22px">
                  <div class="fyxxfl">最多入住天数:</div>
                  <div class="xzkcss">
                    <el-input
                      label-width="140px"
                      placeholder="请输入"
                      v-model="formData.zdrzts"
                    >
                      <template slot="append">天</template>
                    </el-input>
                  </div>
                </div>
              </div>
              <div style="position: relative">
                <div
                  style="
                    position: absolute;
                    left: -30px;
                    width: 5px;
                    height: 22px;
                    background-color: #ff5e79;
                  "
                ></div>
                <h5
                  style="
                    height: 27px;
                    line-height: 22px;
                    font-size: 16px;
                    color: #212121;
                    font-weight: 600;
                  "
                >
                  预订要求
                </h5>
              </div>
              <div>
                <!-- <div style="margin-right: 50px; margin-top: 22px">
                  <div class="fyxxfl">接待性别:</div>
                  <div class="xzkcss">
                    <el-radio v-model="formData.jdxb" label="1" border
                      >男</el-radio
                    >
                    <el-radio v-model="formData.jdxb" label="2" border
                      >女</el-radio
                    >
                    <el-radio v-model="formData.jdxb" label="3" border
                      >不限</el-radio
                    >
                  </div>
                </div> -->
                <div style="margin-right: 50px; margin-top: 22px">
                  <div class="fyxxfl">允许聚会:</div>
                  <div class="xzkcss">
                    <el-radio v-model="formData.yxjh" label="是" border
                      >是</el-radio
                    >
                    <el-radio v-model="formData.yxjh" label="否" border
                      >否</el-radio
                    >
                  </div>
                </div>
                <div style="margin-right: 50px; margin-top: 22px">
                  <div class="fyxxfl">允许吸烟:</div>
                  <div class="xzkcss">
                    <el-radio v-model="formData.yxxy" label="是" border
                      >是</el-radio
                    >
                    <el-radio v-model="formData.yxxy" label="否" border
                      >否</el-radio
                    >
                  </div>
                </div>
                <div style="margin-right: 50px; margin-top: 22px">
                  <div class="fyxxfl">允许做饭:</div>
                  <div class="xzkcss">
                    <el-radio v-model="formData.yxzf" label="是" border
                      >是</el-radio
                    >
                    <el-radio v-model="formData.yxzf" label="否" border
                      >否</el-radio
                    >
                  </div>
                </div>
                <div style="margin-right: 50px; margin-top: 22px">
                  <div class="fyxxfl">允许携带宠物:</div>
                  <div class="xzkcss">
                    <el-radio v-model="formData.yxxdcw" label="是" border
                      >是</el-radio
                    >
                    <el-radio v-model="formData.yxxdcw" label="否" border
                      >否</el-radio
                    >
                  </div>
                </div>
                <div style="margin-right: 50px; margin-top: 22px">
                  <div class="fyxxfl">接待境外人士:</div>
                  <div class="xzkcss">
                    <el-radio v-model="formData.jdjwrs" label="是" border
                      >是</el-radio
                    >
                    <el-radio v-model="formData.jdjwrs" label="否" border
                      >否</el-radio
                    >
                  </div>
                </div>
              </div>
              <div style="position: relative">
                <div
                  style="
                    position: absolute;
                    left: -30px;
                    width: 5px;
                    height: 22px;
                    background-color: #ff5e79;
                  "
                ></div>
                <h5
                  style="
                    height: 27px;
                    line-height: 22px;
                    font-size: 16px;
                    color: #212121;
                    font-weight: 600;
                  "
                >
                  其他要求
                </h5>
              </div>
              <div style="margin-right: 50px; margin-top: 22px">
                <div class="fyxxfl">其他要求</div>
                <div class="xzkcss">
                  <el-input
                    maxlength="2000"
                    type="textarea"
                    show-word-limit
                    :rows="2"
                    placeholder="房客使用您的房间需要遵守的规则 (选填)"
                    v-model="formData.qtyq"
                    :autosize="{ minRows: 9, maxRows: 2 }"
                  >
                  </el-input>
                </div>
              </div>
            </div>
          </div>
        </el-form>
      </div>
      <!-- 地址 -->
    </div>
    <!-- 底部 -->
    <el-dialog title="" :visible.sync="centerDialogVisible" width="30%">
      <span style="font-weight: 700; font-size: 20px; color: #000"
        >房源信息未完成，确认离开？</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button
          style="border-color: #7b7b7b; color: #252525; font-size: 16px"
          @click="qdout"
          >确定</el-button
        >
        <el-button
          style="
            background-color: #ff5f7a;
            color: #fff;
            font-weight: 700;
            font-size: 16px;
          "
          @click="centerDialogVisible = false"
          >再想想</el-button
        >
      </span>
    </el-dialog>
    <div style="width: 100%; position: fixed; bottom: 0">
      <div class="bottom-wrapper">
        <div
          style="
            font-size: 16px;
            margin-left: 30px;
            line-height: 22px;
            color: #212121;
            cursor: pointer;
          "
        >
          <el-button @click="outtc" round>退出</el-button>
        </div>
        <div style="display: flex">
          <el-button style="color: #fff" @click="syb" type="danger" round
            >上一步</el-button
          >
          <el-button
            v-show="bcjx"
            style="color: #fff"
            @click="nextbz"
            type="danger"
            round
            >保存并继续</el-button
          >
          <el-button
            v-show="fbfy"
            style="color: #fff"
            @click="addfw"
            type="danger"
            round
            >发布</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      centerDialogVisible: false,
      bcjx: true,
      fbfy: false,
      card: "",
      form: {},
      active: 0,
      formRuleshxss: {
        tcw: [{ required: true, message: "!请选择", trigger: "blur" }],
        dt: [{ required: true, message: "!请选择", trigger: "blur" }],
        wxwl: [{ required: true, message: "!请选择", trigger: "blur" }],
        ch: [{ required: true, message: "!请选择", trigger: "blur" }],
        cpgh: [{ required: true, message: "!请选择", trigger: "blur" }],
        tx: [{ required: true, message: "!请选择", trigger: "blur" }],
        kt: [{ required: true, message: "!请选择", trigger: "blur" }],
        yxwl: [{ required: true, message: "!请选择", trigger: "blur" }],
        cdz: [{ required: true, message: "!请选择", trigger: "blur" }],
        fftcw: [{ required: true, message: "!请选择", trigger: "blur" }],
        znms: [{ required: true, message: "!请选择", trigger: "blur" }],
        yssb: [{ required: true, message: "!请选择", trigger: "blur" }],
        jjfw: [{ required: true, message: "!请选择", trigger: "blur" }],
        jzfw: [{ required: true, message: "!请选择", trigger: "blur" }],
        lyf: [{ required: true, message: "!请选择", trigger: "blur" }],
        mftcw: [{ required: true, message: "!请选择", trigger: "blur" }],
      },
      formDatahxss: {
        lyf: "有",
        jzfw: "有",
        jjfw: "有",
        yssb: "有",
        znms: "有",
        mftcw: "有",
        fftcw: "有",
        cdz: "有",
        yxwl: "有",
        kt: "有",
        tx: "有",
        cpgh: "换客换床品",
        tcw: "有",
        dt: "有",
        wxwl: "有",
        ch: "有",
        wyss: [],
        aqbz: [],
        rzfw: [],
        jjss: [],
        ccss: [],
        xxyl: [],
        tbss: [],
        etss: [],
        zb: [],
      },
      formRules: {
        prje: [
          {
            required: true,
            message: "!请设置周日一周四每晚的房源价格",
            trigger: "blur",
          },
        ],
        qtje: [
          {
            required: true,
            message: "!请设置周五一周六每晚的房源价格",
            trigger: "blur",
          },
        ],
        jjrje: [
          {
            required: true,
            message: "!请设置节假日每晚的房源价格",
            trigger: "blur",
          },
        ],
        czmj: [
          {
            required: true,
            message: "请输入范围在1-9999的出租面积",
            trigger: "blur",
          },
        ],
        xxdz: [{ required: true, message: "请输入详细地址", trigger: "blur" }],
        dymph: [
          { required: true, message: "请输入单元门牌号", trigger: "blur" },
        ],
        fybt: [{ required: true, message: "请输入房源标题", trigger: "blur" }],
        fyjs: [{ required: true, message: "请输入房源介绍", trigger: "blur" }],
      },

      formData: {
        radioczlx: "1",
        jdjwrs: "否",
        yxxdcw: "否",
        yxzf: "否",
        yxxy: "否",
        yxjh: "否",
        jdxb: "3",
        zsrzts: "1",
        zdrzts: "365",
        tdgz: "宽松",
        radio1yj: "否",
        yj: "",
        jkf: "",
        jkrs: "",
        sfyxjk: "否",
        prje: "",
        qtje: "",
        jjrje: "",
        cws: 1,
        radiofywz: "1",
        xqdz: "",
        dymph: "",
        fybt: "",
        fyjs: "",
        fyld: "",
        wzzb: "",
        jkfms: "",
        qtfysm: "",
        tlfys: "1",
        czmj: "",
        yjrs: "1",
        wsjs: "1",
        cfs: "1",
        kts: "1",
        wss: "1",
        fylx: "",
        ydqtyq: "",
        fmt: "",
      },
      wsimgList: [],
      ktimgList: [],
      wsjimgList: [],
      cfimgList: [],
      qtimgList: [],
      fmimgList: [],
      housePicList: [],
      jkrsoptions: [
        {
          value: "1",
          label: "1",
        },
        {
          value: "2",
          label: "2",
        },
        {
          value: "3",
          label: "3",
        },
        {
          value: "4",
          label: "4",
        },
        {
          value: "5",
          label: "5",
        },
      ],
      yjjeshow: false,
      jkfshow: false,
      wyss1: [
       
      ],
      aqbz1: [
      
      ],
      rzfw1: [
     
      ],
      jjss1: [
      
      ],
      ccss1: [
      
      ],
      xxyl1: [
      
      ],
      tbss1: [
        
      ],
      etss1: [

      ],
      zb1: [
        
      ],
      yyfydz: true,
      xfydz: false,
      fylxoptions: [
        {
          value: "公寓",
        },
        {
          value: "其他",
        },
        {
          value: "农村房屋",
        },
        {
          value: "城中村",
        },
        {
          value: "居民小区",
        },
      ],

      fwhxoptions: [
        {
          value: "1",
          label: "1室",
        },
        {
          value: "2",
          label: "2室",
        },
        {
          value: "3",
          label: "3室",
        },
      ],

      fwhxoptionT: [
        {
          value: "0",
          label: "0厅",
        },
        {
          value: "1",
          label: "1厅",
        },
        {
          value: "2",
          label: "2厅",
        },
        {
          value: "3",
          label: "3厅",
        },
      ],

      fwhxoptionC: [
        {
          value: "0",
          label: "0厨",
        },
        {
          value: "1",
          label: "1厨",
        },
        {
          value: "2",
          label: "2厨",
        },
        {
          value: "3",
          label: "3厨",
        },
      ],

      fwhxoptionW: [
        {
          value: "0",
          label: "0卫",
        },
        {
          value: "1",
          label: "1卫",
        },
        {
          value: "2",
          label: "2卫",
        },
        {
          value: "3",
          label: "3卫",
        },
      ],
      imageUrl1: "",
      xqdzList: [],
      sslist: [],
    };
  },
  created() {
    this.getFyDzList();
    let cardNum = "130132199910210012";
    this.card = cardNum.replace(/(\w{6})\w*(\w{4})/, "$1******$2");
    this.getLoginInfo();
    this.getunit();
  },
  methods: {
    onyydzId(id) {
      this.formData.houseId = id;
    },
    getunit() {
      this.$axios.get("/api/adminPC/MsFacility/unit").then((res) => {
        this.wyss1 = res.data.data[0].children
        this.aqbz1 = res.data.data[1].children
        this.rzfw1 = res.data.data[2].children
        this.jjss1 = res.data.data[3].children
        this.ccss1 = res.data.data[4].children
        this.xxyl1 = res.data.data[5].children
        this.tbss1 = res.data.data[6].children
        this.etss1 = res.data.data[7].children
        this.zb1 = res.data.data[8].children
      });
    },
    getLoginInfo() {
      this.$axios
        .get("/api/adminPC/msOperator/getLoginInfo")
        .then((res) => {
          this.form = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    mffbfj() {
      this.$router.push("/fyfb");
    },
    // 获取原有地址列表
    getFyDzList() {
      this.$axios.get("/api/adminPC/MsHouse/fbfjxx").then(
        (res) => {
          this.xqdzList = res.data.data;
        },
        (error) => {}
      );
    },
    kstd() {
      console.log(this.formData.tdgz, "tdgz");
    },
    yjradios() {
      if (this.formData.radio1yj == "是") {
        this.yjjeshow = true;
      }
    },
    yjradiof() {
      if (this.formData.radio1yj == "否") {
        this.yjjeshow = false;
      }
    },
    jkfradios() {
      if (this.formData.sfyxjk == "是") {
        this.jkfshow = true;
      }
    },
    jkfradiof() {
      if (this.formData.sfyxjk == "否") {
        this.jkfshow = false;
      }
    },
    syb() {
      if (this.active > 0) {
        this.active--;
      }
      if (this.active == 6) {
        this.bcjx = false;
        this.fbfy = true;
      } else {
        this.bcjx = true;
        this.fbfy = false;
      }
    },
    // 卧室
    handleRemovews(file, fileList) {
      // console.log(file, fileList);
    },
    handlePictureCardPreviewws(file) {
      this.wsimgList.push({
        name: file.data.url,
        url: "http://192.168.100.166/images" + file.data.url,
        zplb: "1",
      });
    },
    // 客厅
    handleRemovekt(file, fileList) {
      // console.log(file, fileList);
    },
    handlePictureCardPreviewkt(file) {
      this.ktimgList.push({
        name: file.data.url,
        url: "http://192.168.100.166/images" + file.data.url,
        zplb: "2",
      });
    },
    // 卫生间
    handleRemovewsj(file, fileList) {
      // console.log(file, fileList);
    },
    handlePictureCardPreviewwsj(file) {
      this.wsjimgList.push({
        name: file.data.url,
        url: "http://192.168.100.166/images" + file.data.url,
        zplb: "3",
      });
    },
    // 厨房
    handleRemovecf(file, fileList) {
      // console.log(file, fileList);
    },
    handlePictureCardPreviewcf(file) {
      this.cfimgList.push({
        name: file.data.url,
        url: "http://192.168.100.166/images" + file.data.url,
        zplb: "4",
      });
    },
    // 其他
    handleRemoveqt(file, fileList) {
      // console.log(file, fileList);
    },
    handlePictureCardPreviewqt(file) {
      this.qtimgList.push({
        name: file.data.url,
        url: "http://192.168.100.166/images" + file.data.url,
        zplb: "5",
      });
    },
    // 封面

    handleRemovefm(file, fileList) {
      // console.log(file, fileList);
    },
    handlePictureCardPreviewfm(file) {
      this.fmimgList.push({
        name: file.data.url,
        url: "http://192.168.100.166/images" + file.data.url,
        zplb: "6",
      });
    },
    // handleUploadSuccessFmt(resp, file) {
    //   if (resp.code !== 0) {
    //     this.$message.error(resp.msg);
    //     return;
    //   }
    //   this.formData.fmt = resp.data.url;
    //   this.imageUrl1 = URL.createObjectURL(file.raw);
    // },
    // handleChange(value) {
    //   console.log(value);
    // },
    yydz() {
      if (this.formData.radiofywz == 1) {
        this.yyfydz = true;
        this.xfydz = false;
      }
    },
    xwz() {
      if (this.formData.radiofywz == 2) {
        this.yyfydz = false;
        this.xfydz = true;
      }
    },
    nextbz() {
      if (
        this.active == 0 &&
        this.formData.radiofywz == 1 &&
        this.formData.xqdz == ""
      ) {
        this.$message.error("请选择已有地址");
      } else if (
        this.active == 0 &&
        this.formData.radiofywz == 2 &&
        this.formData.dymph == "" &&
        this.formData.xqdz == ""
      ) {
        this.$refs["formyz"].validate((valid) => {});
      } else if (this.active == 2 && this.formData.fybt.length < 6) {
        this.$message.error("房屋标题不可少于6个字");
      } else if (this.active == 2 && this.formData.fyjs.length < 20) {
        this.$message.error("房屋介绍不可少于20个字");
      } else if (this.active == 4 && this.wsimgList == "") {
        this.$message.error("请上传卧室照片");
      } else if (this.active == 4 && this.ktimgList == "") {
        this.$message.error("请上传客厅照片");
      } else if (this.active == 4 && this.wsjimgList == "") {
        this.$message.error("请上传卫生间照片");
      } else if (this.active == 4 && this.cfimgList == "") {
        this.$message.error("请上传厨房照片");
      } else if (this.active == 4 && this.qtimgList == "") {
        this.$message.error("请上传其他照片");
      } else if (this.active == 4 && this.fmimgList == "") {
        this.$message.error("请上传封面照片");
      } else {
        this.$refs["formyz"].validate((valid) => {
          if (valid) {
            if (this.active++ > 5) {
              this.active = 6;
              this.$message.success("保存成功");
            }
          }
        });
      }
      if (this.active == 6) {
        this.bcjx = false;
        this.fbfy = true;
      } else {
        this.bcjx = true;
        this.fbfy = false;
      }
    },
    qdout() {
      this.$router.push("/kzt");
      this.formData = {};
    },
    outtc() {
      this.centerDialogVisible = true;
    },
    // 发布
    addfw() {
      this.addHousePicList();
      this.$axios
        .post("/api/adminPC/MsHouseFb/add", this.formData)
        .then((res) => {
          this.formDatahxss.fwbm = res.data.data;
          console.log(res.data);
          if (res.data.code == 0) {
            // 添加图片
            this.$axios
              .post("/api/adminPC/HousePic/createList", {
                housePicList: this.housePicList,
                fwbm: res.data.data,
              })
              .then((res) => {})
              .catch((err) => {});
            // 添加设施服务
            this.$axios
              .post("/api/adminPC/MsFacility/create", this.formDatahxss)
              .then((res) => {})
              .catch((err) => {});
            this.$message.success("提交成功,请耐心等待审核结果");
            // 房源列表
            this.$router.push("/fblb");
          }
        })
        .catch((err) => {});
    },
    // 添加处理房间信息照片
    addHousePicList() {
      for (var i = 0; i < this.wsimgList.length; i++) {
        this.housePicList.push({ url: this.wsimgList[i].name, zplb: "1" });
      }
      for (var i = 0; i < this.ktimgList.length; i++) {
        this.housePicList.push({ url: this.ktimgList[i].name, zplb: "2" });
      }
      for (var i = 0; i < this.wsjimgList.length; i++) {
        this.housePicList.push({ url: this.wsjimgList[i].name, zplb: "3" });
      }
      for (var i = 0; i < this.cfimgList.length; i++) {
        this.housePicList.push({ url: this.cfimgList[i].name, zplb: "4" });
      }
      for (var i = 0; i < this.qtimgList.length; i++) {
        this.housePicList.push({ url: this.qtimgList[i].name, zplb: "5" });
      }
      for (var i = 0; i < this.fmimgList.length; i++) {
        this.housePicList.push({ url: this.fmimgList[i].name, zplb: "6" });
      }
    },
    exitLogin() {
      this.$axios
        .get("/api/adminPC/msWeiXinController/logout")
        .then((res) => {
          this.$message.success("退出成功");
          this.$router.push("/");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
::v-deep .el-upload {
  border: 1px solid #ccc !important;
  border-radius: 6px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.clock-icon {
  width: 12px;
  height: 12px;
  margin: 5px 0;
  background: url(../../../assets/zb1.png);
  background-size: cover;
}

.imgts {
  font-size: 14px;
  color: #757575;
  line-height: 30px;
}

.tips {
  float: left;
  width: 32px;
  height: 80px;
  background-image: url(../../../assets/tsdeng.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.r1 {
  font-family: PingFangSC-Regular;
  white-space: pre-line;
  font-size: 14px;
  color: #757575;
  letter-spacing: 0.01px;
  font-weight: 400;
  /* line-height: 0px; */
}

.r3 {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  letter-spacing: 0.01px;
  /* line-height: 0px; */
  font-weight: 400;
  color: #8b2347;
}

.el-input {
  width: 215px;
}

.fyxxfl {
  float: left;
  display: flex;
  align-items: center;
  height: 40px;
  line-height: normal;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  text-align: left;
  color: #877575;
}

.xzkcss {
  line-height: 40px;
  position: relative;
  font-size: 14px;
  margin-left: 120px;
}

.bottom-wrapper {
  width: 71%;
  height: 70px;
  background: #fff;
  margin: 0 auto;
  border-radius: 8px;
  align-items: center;
  position: relative;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.05);
}

.tabs {
  margin: 30px 0;
  /* height: 30px; */
  width: 100%;
  box-sizing: border-box;
  padding-left: 30px;
  padding-right: 30px;
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.titlelx {
  width: 140px;
  height: 17px;
  box-sizing: border-box;
  padding-left: 30px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #757575;
  font-weight: 400;
  cursor: pointer;
}

.basic-info {
  box-sizing: border-box;
  min-height: 100vh;
  border-radius: 8px;
  padding: 0px 100px 0 100px;
  padding-top: 30px;
  padding-bottom: 85px;
  margin: 0 auto;
  width: 1400px;
}

.header-container {
  height: 60px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: #fff;
}

.header-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.el-header {
  padding: 0 30px;
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.el-dropdown-link {
  font-size: 14px;
  color: #212121;
  width: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 84px;
  letter-spacing: 2px;
  padding-right: 6px;
}

.el-button {
  position: relative;
  width: 143px;
  box-sizing: border-box;
  height: 40px;
  line-height: 38px;
  padding: 0 17px;
  border: 2px solid #ff5f7a;
  border-radius: 25px;
  letter-spacing: 2px;
  color: #ff5f7a;
  text-align: center;
  margin: 20px;
}

::v-deep .el-radio__input.is-checked .el-radio__inner {
  background: #000 !important;
  border-color: #000 !important;
}

::v-deep .el-radio.is-bordered.is-checked {
  border-color: #000 !important;
}

::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #000 !important;
}
::v-deep .el-dialog {
  border-radius: 20px;
}
</style>