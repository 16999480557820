import Vue from 'vue'
import VueRouter from 'vue-router'

// 组件通过export暴露接口，路由中导入组件
import Login from '../pages/login/index'
import zhuce from '../pages/login/zhuce'
import Home from '../pages/home/index'
import fyfb from '../pages/home/fyfb/fyfb'
import fyfbnext from '../pages/home/fyfb/fyfbnext'
import fyfbUpdate from '../pages/home/fyfb/fyfbUpdate'
import cjwt from '../pages/home/kzt/cjwt'

// 导入 vue-router 依赖
Vue.use(VueRouter);

// export default new VueRouter({
  const routes = [
    {
      path: '/',
      name: 'login',
      component: Login
    },
    {
      path: '/zhuce',
      name: 'zhuce',
      component: zhuce
    },
    {
      path: '/cjwt',
      name: 'cjwt',
      component: cjwt
    },
    {
      path: '/fyfb',
      name: 'fyfb',
      component: fyfb
    },
    {
      path: '/fyfbnext',
      name: 'fyfbnext',
      component: fyfbnext
    },
    {
      path: '/fyfbUpdate',
      name: 'fyfbUpdate',
      component: fyfbUpdate
    },
    {
      path: '/home',
      name: 'home',
      component: Home,
      meta: {
        title: '首页'
      },
      children: [
        {
          path: '/kzt',
          component: () => import('../pages/home/kzt/kzt.vue'),
          meta: {
            title: '工作台'
          }
        },
        {
          path: '/fylb',
          component: () => import('../pages/home/fygl/fylb.vue'),
          meta: {
            title: '房源列表'
          }
        },
        {
          path: '/ftrl',
          component: () => import('../pages/home/ftrl/ftrl.vue'),
          meta: {
            title: '房态日历'
          }
        },
        {
          path: '/msdd',
          component: () => import('../pages/home/ddgl/msdd.vue'),
          meta: {
            title: '民宿订单'
          }
        },

        {
          path: '/xttz',
          component: () => import('../pages/home/xttz/xttz.vue'),
          meta: {
            title: '民宿订单'
          }
        },

        {
          path: '/dpgl',
          component: () => import('../pages/home/dpgl/dpgl.vue'),
          meta: {
            title: '点评管理'
          }
        },
        {
          path: '/yggl',
          component: () => import('../pages/home/yggl/yggl.vue'),
          meta: {
            title: '员工管理'
          }
        },
        {
          path: '/zkxx',
          component: () => import('../pages/home/zkxx/zkxx.vue'),
          meta: {
            title: '住客信息'
          }
        },
        {
          path: '/msgl',
          component: () => import('../pages/home/msgl/msgl.vue'),
          meta: {
            title: '门锁管理'
          }
        },

        {
          path: '/grzl',
          component: () => import('../pages/home/grzl/grzl.vue'),
          meta: {
            title: '个人资料'
          }
        },
        {
          path: '/bjds',
          component: () => import('../pages/home/jdgd/bjds.vue'),
          meta: {
            title: '保洁打扫'
          }
        },
        {
          path: '/gcwx',
          component: () => import('../pages/home/jdgd/gcwx.vue'),
          meta: {
            title: '工程维修'
          }
        },
        {
          path: '/splb',
          component: () => import('../pages/home/scgl/splb.vue'),
          meta: {
            title: '商品列表'
          }
        },
        {
          path: '/scdd',
          component: () => import('../pages/home/scgl/scdd.vue'),
          meta: {
            title: '商城订单'
          }
        },
        {
          path: '/ddgl',
          component: () => import('../pages/home/ddgl/msdd.vue'),
          meta: {
            title: '订单管理'
          }
        },
        {
          path: '/czpz',
          component: () => import('../pages/home/czpz/czpz.vue'),
          meta: {
            title: '充值配置'
          }
        },
        {
          path: '/hytx',
          component: () => import('../pages/home/hytx/hytx.vue'),
          meta: {
            title: '会员体系'
          }
        },
        {
          path: '/fyfb',
          component: () => import('../pages/home/fyfb/fyfb.vue'),
          meta: {
            title: '房源发布'
          }
        },
        {
          path: '/fblb',
          component: () => import('../pages/home/fblb/fblb.vue'),
          meta: {
            title: '发布列表'
          }
        },
      ]
    },
  ]
// });

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  // console.log(to,'to');
  // console.log(from,'from');
  //如果去往登录页则放行 
    if (to.path === '/' || to.path === '/kzt' || to.path === '/zhuce') {
      next();
    } else {
      // 从本地存储里获取token
      let token = localStorage.getItem('token');
      // 判断token是否为空如果为空则跳转到登录页 如果有则放行
      if (token === null || token === '') {
        next({path:'/'});
      } else {
        next();
      }
    }
  });
  
  export default router