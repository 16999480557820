<template>
  <div>
    <img
      style="width: 100%; height: 100%; position: absolute"
      src="../../assets/dlbj.png"
      alt=""
    />
    <!-- <div class="tabar" style="display: flex; height: 60px; line-height: 50px; background-color: #282828;color:#fff"> -->
    <div
      style="position: absolute; left: 2%;top:2%; font-size: 20px; font-weight: 700"
    >
      石家庄民宿(网约房)PMS系统
    </div>
    <!-- <div @click="login" class="login" style="position: absolute; right: 7%">
      登录 / 注册
    </div>
    <div @click="zhmm" class="login" style="position: absolute; right: 2%">
      找回密码
    </div> -->
    <!-- <div style="position: absolute; right: 1%"> -->
    <!-- <el-button type="danger" round plain class="el-button" @click="mffbfj"
          >免发布房间</el-button
        > -->
    <!-- </div> -->
    <!-- </div> -->
    <!-- <div style="padding:20px">
      <img
        style="width: 100%; height: 800px; border-radius: 10px"
        src="../../assets/dlbj.png"
        alt=""
      />
    </div> -->
    <div style=" position: absolute; top: 30%; right: 24%;display:flex;width:1000px;height: 400px;
        background-color: #3fc6ff;
    border-radius: 15px;">
    <img style="width:500px;" src="../../assets/dlmkbj.png" alt="">
    <div  style="width:400px;padding: 50px;background-color: #fff;border-top-right-radius: 15px; border-bottom-right-radius: 15px; ">
      <div>
        <div style="width: 100%; display: flex">
          <div
            style="font-size: 24px; color: #000; font-weight: 700; width: 65%"
          >
            用户登录
          </div>
          <div @click="gozhuce" style="cursor: pointer">没有账号? 去注册</div>
        </div>
        <div style="margin-top: 40px">
          <el-form ref="form1" :model="form1" :rules="formRules">
            <el-form-item prop="phone">
              <!-- <el-input
                  maxlength="11"
                  placeholder="手机号"
                  v-model="form1.phone"
                ></el-input> -->
              <el-input placeholder="手机号" v-model="form1.phone"></el-input>
            </el-form-item>
            <el-form-item style="margin-top: 15px" prop="password">
              <el-input
                show-password
                placeholder="密码"
                v-model="form1.password"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>

        <div style="text-align: center">
          <el-button
            :loading="logining"
            @click="loginbtn"
            style="
              border-radius: 25px;
              color: #fff;
              font-size: 18px;
              font-weight: 700;
              width: 100%;
            "
          >
            登录
          </el-button>
        </div>
      </div>
      
      <div @click="zhmm" style="width: 100%; display: flex; justify-content: end">
        <div style="cursor: pointer;margin-top:20px">
          找回密码
        </div>
      </div>
      </div>
    </div>
    <div>
      <el-dialog
        title=""
        :visible.sync="centerDialogVisible"
        width="25%"
        center
      >
        <div style="padding: 25px">
          <div style="width: 100%; display: flex">
            <div
              style="font-size: 24px; color: #000; font-weight: 700; width: 70%"
            >
              用户登录
            </div>
            <div @click="gozhuce" style="cursor: pointer">没有注册? 去注册</div>
          </div>

          <div style="margin-top: 40px">
            <el-form ref="form1" :model="form1" :rules="formRules">
              <el-form-item prop="phone">
                <!-- <el-input
                  maxlength="11"
                  placeholder="手机号"
                  v-model="form1.phone"
                ></el-input> -->
                <el-input placeholder="手机号" v-model="form1.phone"></el-input>
              </el-form-item>
              <el-form-item style="margin-top: 15px" prop="password">
                <el-input
                  show-password
                  placeholder="密码"
                  v-model="form1.password"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>

          <div style="text-align: center">
            <el-button
              :loading="logining"
              @click="loginbtn"
              style="
                border-radius: 25px;
                background-color: #ff5f7a;
                color: #fff;
                font-size: 18px;
                font-weight: 700;
                width: 100%;
              "
            >
              登录
            </el-button>
          </div>
        </div>
      </el-dialog>

      <el-dialog
        title=""
        :visible.sync="centerDialogVisiblezhmm"
        width="25%"
        center
      >
        <div style="padding: 25px">
          <div style="width: 100%; display: flex">
            <div
              style="font-size: 24px; color: #000; font-weight: 700; width: 70%"
            >
              找回密码
            </div>
          </div>

          <div style="margin-top: 40px">
            <el-form ref="form1zhmm" :model="form1zhmm" :rules="formRuleszhmm">
              <el-form-item prop="phone">
                <el-input
                  maxlength="11"
                  placeholder="登录手机号"
                  v-model="form1zhmm.phone"
                ></el-input>
              </el-form-item>
              <el-form-item style="margin-top: 15px" prop="shtyxydm">
                <el-input
                  placeholder="社会统一信用代码"
                  v-model="form1zhmm.shtyxydm"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>

          <div style="text-align: center">
            <el-button
              :loading="zhmming"
              @click="zhmmbtn"
              style="
                border-radius: 25px;
                color: #fff;
                font-size: 18px;
                font-weight: 700;
                width: 100%;
              "
            >
              提交
            </el-button>
          </div>
        </div>
      </el-dialog>
      <el-dialog
        title="密码"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose"
      >
        <span>您的密码:{{ dlmmshow }}</span>
        <span slot="footer" class="dialog-footer">
          <el-button type="" @click="dialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <Vcode :show="isShow" @success="onSuccess" @close="onClose" />
  </div>
</template>

<script>
import Vcode from "vue-puzzle-vcode";
export default {
  components: {
    Vcode,
  },
  data() {
    return {
      isShow: false,
      dlmmshow: "",
      dialogVisible: false,
      centerDialogVisiblezhmm: false,
      zhmming: false,
      logining: false,
      formRules: {
        // phone: [
        //   { required: true, message: "请输入手机号", trigger: "blur" },
        //   {
        //     required: true,
        //     pattern:
        //       /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/,
        //     message: "请输入正确的手机号码",
        //     trigger: "blur",
        //   },
        // ],
        // password: [
        //   { required: true, message: "请输入密码", trigger: "blur" },
        //   {
        //     required: true,
        //     pattern: /^[^\u4e00-\u9fa5 ]{6,16}$/,
        //     message: "密码不少于6位,不含有空格和汉字",
        //     trigger: "blur",
        //   },
        // ],
      },
      formRuleszhmm: {
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            required: true,
            pattern:
              /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        shtyxydm: [
          {
            required: true,
            message: "请输入社会统一信用代码",
            trigger: "blur",
          },
          {
            required: true,
            pattern: /[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}/,
            message: "社会统一信用代码错误",
            trigger: "blur",
          },
        ],
      },
      centerDialogVisible: false,
      form1: {
        phone: "",
        password: "",
      },
      form1zhmm: {
        phone: "",
        shtyxydm: "",
      },
    };
  },
  created() {
    this.form1.phone = localStorage.getItem("phone", this.form1.phone);
  },
  methods: {
    onSuccess(msg) {
      this.isShow = false; // 通过验证后，需要手动隐藏模态框
      this.$axios
        .post("/api/adminPC/PMS/login", {
          dlzh: this.form1.phone,
          dlmm: this.form1.password,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message.success("登录成功");
            this.$router.push("/kzt");
          } else {
            this.$message.error("账号或密码错误");
          }
          localStorage.setItem("phone", this.form1.phone);
          localStorage.setItem("token", res.data.data.token);
          localStorage.setItem("msmc", res.data.data.user.msmc);
          localStorage.setItem("fzrdh", res.data.data.user.fzrdh);
          localStorage.setItem("shtyxydm", res.data.data.user.shtyxydm);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 用户点击遮罩层，应该关闭模态框
    onClose() {
      this.isShow = false;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    zhmmbtn() {
      this.$refs["form1zhmm"].validate((valid) => {
        if (valid) {
          this.$axios
            .post("/api/adminPC/PMS/zhDlmm", {
              dlzh: this.form1zhmm.phone,
              shtyxydm: this.form1zhmm.shtyxydm,
            })
            .then((res) => {
              console.log(res.data.code, "code");
              if (res.data.code == 0) {
                this.dlmmshow = res.data.data;
                this.dialogVisible = true;
                this.centerDialogVisiblezhmm = false;
              } else if (res.data.code == 200400) {
                this.$message.error(res.data.msg);
              }
            });
        }
      });
    },
    zhmm() {
      this.centerDialogVisiblezhmm = true;
    },
    mffbfj() {
      this.$router.push("/fyfb");
    },

    loginbtn() {
      this.$refs["form1"].validate((valid) => {
        if (valid) {
          this.isShow = true;
          this.centerDialogVisible = false;
          // this.$axios
          //   .post("/api/admin/PMS/login", {
          //     dlzh: this.form1.phone,
          //     dlmm: this.form1.password,
          //   })
          //   .then((res) => {
          //     if (res.data.code == 0) {
          //       this.$message.success("登录成功");
          //       this.$router.push("/kzt");
          //     } else {
          //       this.$message.error(res.data.msg);
          //     }
          //     localStorage.setItem("token", res.data.data.token);
          //     localStorage.setItem("msmc", res.data.data.user.msmc);
          //     localStorage.setItem("dlzh", res.data.data.user.dlzh);
          //     localStorage.setItem("shtyxydm", res.data.data.user.shtyxydm);
          //   })
          //   .catch((err) => {
          //     console.log(err);
          //   });
        } else {
          return false;
        }
      });
      // }
    },
    login() {
      // this.centerDialogVisible = true;
    },
    gozhuce() {
      this.$router.push("/zhuce");
    },
    gologin() {
      // this.centerDialogVisible = true;
      this.centerDialogVisiblezhuce = false;
    },
  },
};
</script>
<style scoped>
.login:hover {
  color: #ff5f7a;
  cursor: pointer;
}

::v-deep .el-dialog {
  border-radius: 20px;
}

::v-deep .el-input__inner {
  height: 50px;
}

/* ::v-deep .el-dialog__title {
  font-size: 24px;
  color: #000;
  font-weight: 700;
} */
.el-button {
  position: relative;
  width: 143px;
  /* box-sizing: border-box; */
  height: 50px;
  line-height: 38px;
  padding: 0 17px;
  border: 0px solid #ff5f7a;
  border-radius: 25px;
  /* letter-spacing: 2px; */
  /* color: #ff5f7a; */
  background-color: #769efe;
  text-align: center;
}
</style>
