<template>
  <div>
    <!-- <el-container> -->
      <wrapper></wrapper>
      <el-container style="background-color: #fbfbfb; height: 100%">
        <el-aside width="210px" style="">
          <sidebar :lists="lists"></sidebar>
        </el-aside>
        <el-main style="padding: 10px;">
          <router-view style="background-color: #fff"></router-view>
        </el-main>
      </el-container>
    <!-- </el-container> -->
  </div>
</template>

<script>

import wrapper from '@/components/wrapper';
import sidebar from '@/components/sidebar';

export default {
  components: {
    sidebar,wrapper
  },
  data() {
    return {
      lists: [
        // {
        //   title: '房源管理',
        //   children: [
        //     { index: 'ftrl', title: '房态日历' },
        //     { index: 'fylb', title: '房源列表' },
        //     // { index: 'fyfb', title: '房源发布' },
        //   ]
        // },
        {
          title: '设置',
          children: [
            { index: 'xttz', title: '系统通知' },
            { index: 'grzl', title: '个人资料' },
          ]
        }
      ]
    }
  },
  created() {

  },
  methods: {
    
  },
}
</script>

<style scoped>
html,body {
      margin: 0;
      height: 100%;
    }
</style>